import HomeHeader from "@components/common/Header/HomeHeader/Header";
import { useEffect, useRef, useState } from "react";
import "./messages.scss";
import "reactjs-popup/dist/index.css";
import {
  SpecificWhatsappConversationRead,
  getAvailableConfigList,
  getSpecificWhatsappConversation,
  getWhatsappConversations,
  sendWhatsappConversation,
} from "@services/fb.service";
import { QueryRequestHelper } from "@common/query-request-helper";
import { getValue } from "@utils/lodash";
import Loader from "@components/common/Loader/loading";
import InboxContacts from "./components/contacts";
import ChatFooter from "./components/chat-footer";
import ChatContactInfo from "./components/contact-info";
import ChatRightHeader from "./components/chat-right-header";
import ChatDocumentPreview from "./components/chat-doc-preview";
import { mediaUpload } from "@services/upload.service";
import DocumentSvgComponent from "@assets/svg/document";
import CrossSvgComponent from "@assets/svg/cross";
import {
  formatString,
  removeNullOrUndefinedProperties,
} from "@common/text-helpers";
import { getSpecificContact } from "@services/contacts.service";
import { motion } from "framer-motion";
import {
  allPipelines,
  getSpecificPipeline,
  getSpecificPipelineColumns,
} from "@services/pipeline.service";
import { useStateContext } from "@context/profileProvider";
import {
  appendObjectValuesToArray,
  removeDuplicatesById,
} from "@components/helpers/request-helper";
import AccountCircleSvgComponent from "@assets/svg/account-circle";
import Pagination from "@components/Pages/Pipeline/common/pagination";
import _ from "lodash";
import { replacePlaceholders } from "@common/markdown/markdown-helper";
import { useNavigate, useParams } from "react-router-dom";
import ConversationMessages from "./components/conversation-messages";
import { getOrganisationUsers } from "@services/organisations.service";
import FilterSvgComponent from "@assets/svg/filter";
import WaAdvancefilter from "./components/wa-advance-filter";
import ChatFilter from "./components/chat-filter";
import { getAllChatbots } from "@services/chatbot.service";
import { Button, Space, Tooltip } from "antd";
import InboxNoDataPage from "@components/common/NoData/inbox-nodata";
import { SearchOutlined } from "@ant-design/icons";
import useDynamicTitle from "@context/useDynamicTitle";
import ListLoader from "@components/common/Loader";
import InfiniteScroll from "react-infinite-scroll-component";
import { getSpecificLead } from "@services/leads.service";

const contentStyle: React.CSSProperties = {
  padding: 50,
  background: "rgba(0, 0, 0, 0.05)",
  borderRadius: 4,
};

const content = <div style={contentStyle} />;

function Messages(props: any) {
  const params = useParams();
  const urlSearchParams = new URLSearchParams(window.location.search);
  const UrlParams = Object.fromEntries(urlSearchParams.entries());

  const [expanded, setExpanded] = useState(false);
  const [docExpanded, setDocExpanded] = useState(false);
  const [showEmojiPicker, setShowEmojiPicker] = useState(false);
  const [isWAConfigured, setIsWAConfigured] = useState(false);

  const handleToggleWidth = () => {
    resetPipelineForm();
    setExpanded(!expanded);
  };
  const {
    selectedModuleId,
    userId,
    newNotification,
    setNewNotification,
    newStatus,
    setNewStatus,
    unassignedCount,
    assignedCount,
    myCount,
    resolvedCount,
    getAllCount,
    totalInboxCount,
    modules,
  } = useStateContext();

  /* -------------------------------------------------------------------------- */
  /*                                 Socket Section                             */
  /* -------------------------------------------------------------------------- */

  useEffect(() => {
    if (newNotification) {
      if (getValue(newStatus, `conversation.id`, "") === selectedConversation) {
        getSpecificConversation(false, selectedConversation, 1);
        handleReadMessage(
          getValue(newStatus, `conversation`, {}),
          getValue(newStatus, `conversation.id`, ""),
          ""
        );
      } else {
        setNewNotification(false);
      }
      getData(false, 1, "", conversationStatus);
    }
  }, [newNotification, newStatus]);
  const [msgIndex, setMsgIndex] = useState<any>(null);
  const [count, setCount] = useState(0);
  useEffect(() => {
    let msgKey = getValue(newStatus, `msg.id`, "") ? "msg" : "last_msg";
    const msgId = getValue(newStatus, `${msgKey}.id`, "");
    const msgStatus = getValue(newStatus, `${msgKey}.status`, "");
    const conversationId = getValue(newStatus, `conversation.id`, "");
    if (msgId) {
      setNewStatus({});
      setMsgIndex(null);
      if (
        (msgStatus !== "is_read" || count < 2) &&
        msgStatus !== "is_delivered"
      ) {
        getSpecificConversation(false, conversationId, 1);
        if (count > 1) {
          setCount(0);
        }
      }
      if (msgStatus === "is_read") {
        setCount(count + 1);
      }
      if (msgStatus !== "is_delivered" && msgStatus !== "is_read") {
        getData(false, 1, "", conversationStatus);
      }
    }
  }, [
    getValue(newStatus, `msg.id`, ""),
    getValue(newStatus, `last_msg.id`, ""),
    msgIndex,
    newStatus,
  ]);
  /* -------------------------------------------------------------------------- */
  /*                               UseState Section                             */
  /* -------------------------------------------------------------------------- */
  useEffect(() => {
    if (Object.keys(UrlParams).length === 0) {
      getData(true, page_no, query, conversationStatus);
      // getAllPipelines();
      getActiveUsersData();
    }
    getConnectedWhatsappList();
    getChatbotData();
  }, []);
  useEffect(() => {
    if (Object.keys(UrlParams).length > 0) {
      setContactInfo({});
      setSelectedContact({});
      setFormFields([]);
      setExpanded(false);
      setUserDetails(false);
      // getAllPipelines();
      getActiveUsersData();
      getData(true, page_no, query, conversationStatus);
      let waAccountIds: any = urlSearchParams.getAll("waAccountIds[]");
      if (
        getValue(waAccountIds, `length`, 0) > 0 ||
        getValue(UrlParams, `onlyUnread`, "")
      ) {
        let waAccountIds: any = urlSearchParams.getAll("waAccountIds[]");
        setFilterRequest({
          ...filterRequest,
          onlyUnread:
            getValue(UrlParams, `onlyUnread`, "") === "true" ? true : false,
          waAccountIds: waAccountIds,
        });
      }
    }
  }, [window.location.href]);

  /* -------------------------------------------------------------------------- */
  /*                               API Section                                  */
  /* -------------------------------------------------------------------------- */

  /* ----------------------------  Whatsapp Contacts List  --------------------- */
  const [conversations, setConversations] = useState<any[]>([]);
  const [isLoading, setIsLoading] = useState(true);
  const [page_no, setPageNo] = useState(1);
  const [page_size, setPageSize] = useState(100);
  const [totalCount, setTotalCount] = useState(0);
  const handleChangePagination = (page_no: number) => {
    setPageNo(page_no);
    let payload = {
      ...UrlParams,
      page_no: page_no,
      conversationId: selectedConversation,
      searchText: query,
      convStatus: conversationStatus,
      userId: "",
    };
    let queryRequest = QueryRequestHelper(payload);
    navigate(`${window.location.pathname}?${queryRequest}`);
  };

  const [conversationStatus, setConversationStatus] = useState("un-assigned");
  const [unreadCount, setUnreadCount] = useState(0);
  const getData = async (
    status: boolean,
    page_no: number,
    searchText: string,
    convStatus: string
  ) => {
    let waAccountIds: any = urlSearchParams.getAll("waAccountIds[]");
    try {
      setConversationStatus(convStatus);
      setIsLoading(status);
      let payload = {
        page_no: page_no ? page_no : getValue(UrlParams, `page_no`, ""),
        page_size: getValue(UrlParams, `page_size`, "")
          ? getValue(UrlParams, `page_size`, "")
          : page_size,
        search: getValue(UrlParams, `searchText`, "")
          ? getValue(UrlParams, `searchText`, "")
          : searchText,
        conversationStatus: getValue(UrlParams, `convStatus`, "")
          ? getValue(UrlParams, `convStatus`, "")
          : convStatus
          ? convStatus
          : conversationStatus,
        forOrgUser: getValue(UrlParams, `userId`, ""),
        waAccountIds: waAccountIds,
        onlyUnread: getValue(UrlParams, `onlyUnread`, ""),
      };
      let queryRequest = QueryRequestHelper(payload);
      let resp = await getWhatsappConversations(queryRequest);
      if (resp) {
        let result =
          //  getValue(resp, `data.unread`, []).concat(
          getValue(resp, `data.conversations`, []);
        // );
        setUnreadCount(getValue(resp, `data.unread`, 0));
        setConversations(result);
        if (!newNotification) {
          // handleSelectConvesationFromSocket()
          // setSelectedConversation(
          //   getValue(newStatus, `conversation.id`, "")
          //     ? getValue(newStatus, `conversation.id`, "")
          //     : getValue(resp, `data.conversations[${0}].id`, "")
          // );
          // handleSelectConvesation(
          //   getValue(resp, `data.conversations[${0}]`, ""),
          //   status
          // );
          // setSelectedConversation(
          //   getValue(resp, `data.conversations[${0}].id`, "")
          // );
          // getSpecificConversation(
          //   true,
          //   conversationId
          //     ? conversationId
          //     : getValue(resp, `data.unread[${0}].id`, "")
          //     ? getValue(resp, `data.unread[${0}].id`, "")
          //     : getValue(resp, `data.conversations[${0}].id`, "")
          // );
        }
        setTotalCount(getValue(resp, `data.total`, 0));
        setIsLoading(false);
      } else {
        setIsLoading(false);
      }
    } catch (error) {
      setIsLoading(false);
    }
  };
  const getDataStatusRefetch = async (
    status: boolean,
    id: string,
    newPayload: object
  ) => {
    let waAccountIds: any = urlSearchParams.getAll("waAccountIds[]");
    try {
      let conversationsInfo = conversations.find(
        (item: object) => getValue(item, `id`, "") === id
      );
      setIsLoading(status);
      let payload = {
        page_no: getValue(UrlParams, `page_no`, ""),
        page_size: getValue(UrlParams, `page_size`, ""),
        search: getValue(UrlParams, `searchText`, ""),
        conversationStatus: getValue(UrlParams, `convStatus`, "")
          ? getValue(UrlParams, `convStatus`, "")
          : conversationStatus,
        forOrgUser: getValue(UrlParams, `userId`, ""),
        waAccountIds: waAccountIds,
        onlyUnread: getValue(UrlParams, `onlyUnread`, ""),
      };
      let queryRequest = QueryRequestHelper(payload);
      let resp = await getWhatsappConversations(queryRequest);
      if (resp) {
        let result = getValue(resp, `data.conversations`, []);
        setUnreadCount(getValue(resp, `data.unread`, 0));
        setConversations(result);
        let info = result.find(
          (item: object) =>
            getValue(item, `wa_profile_name`, "") ===
            getValue(conversationsInfo, `wa_profile_name`, "")
        );
        if (info) {
          setSelectedConversationInfo({
            ...info,
            last_name: getValue(info, `wa_profile_name`, ""),
            mobile_number: getValue(info, `wa_id`, ""),
          });
        }
        // delete UrlParams.convStatus
        setSelectedConversation(getValue(info, `id`, ""));
        let payload = {};
        if (
          getValue(newPayload, `orgUserId`, "") &&
          getValue(newPayload, `managedBy`, "") === "team"
        ) {
          payload = {
            page_no: 1,
            convStatus: "assigned",
          };
        } else if (
          getValue(newPayload, `chatBotId`, "") &&
          getValue(newPayload, `managedBy`, "") === "chat-bot"
        ) {
          payload = {
            page_no: 1,
            convStatus: "un-assigned",
          };
        } else {
          payload = {
            page_no: 1,
            convStatus: "un-assigned",
          };
        }
        let queryRequest = QueryRequestHelper(payload);
        navigate(`${window.location.pathname}?${queryRequest}`);
      } else {
        setIsLoading(false);
      }
    } catch (error) {
      setIsLoading(false);
    }
  };
  /* ----------------------------  Whatsapp Specific Contactinfo data  --------------------- */

  const [m_page_no, setMPageNo] = useState(1);
  const [m_page_size, setMPageSize] = useState(20);
  const [m_total_count, setMTotalCount] = useState(0);
  const [conversationLoading, setConversationLoading] = useState(false);
  const [conversation, setConversation] = useState<any[]>([]);
  const [selectedContact, setSelectedContact] = useState<any>({});
  const getSpecificConversation = async (
    status: boolean,
    id: string,
    page_no: any
  ) => {
    try {
      setConversationLoading(status);
      let payload = {
        page_no: page_no ? page_no : m_page_no,
        page_size: m_page_size,
      };
      let queryRequest = QueryRequestHelper(payload);
      let resp = await getSpecificWhatsappConversation(id, queryRequest);
      if (resp) {
        const newMessages = getValue(resp, `data.msgs`, []);
        setConversation((prevConvo) => [...newMessages, ...prevConvo]);
        setMTotalCount(getValue(resp, `data.total`, 0));

        setConversationLoading(false);
      } else {
        setConversationLoading(false);
      }
    } catch (error) {
      setConversationLoading(false);
    }
  };

  const fetchMoreMessages = () => {
    if (!conversationLoading && conversation.length < m_total_count) {
      setMPageNo(m_page_no + 1); // Increment page number
      getSpecificConversation(false, selectedConversation, m_page_no + 1); // Fetch more data
    }
  };

  const [selectedConversation, setSelectedConversation] = useState("");
  const [selectedConversationInfo, setSelectedConversationInfo] = useState({});

  const handleRemovePipelineInfo = () => {
    setMPageNo(1);
    setConversation([]);
    setSelectedContact({});
    setStaticFieldRequest({
      ...staticFieldRequest,
      pipeline_id: "",
      stage: "",
    });
    setFormFields([]);
    setExpanded(false);
    setUserDetails(false);
    setContactInfo({});
  };
  const [selectedModule, setSelectedModule] = useState("");
  const handleSelectConvesation = (item: any, status: boolean) => {
    // setConversation([]);
    setSelectedConversationInfo({
      ...item,
      last_name: getValue(item, `wa_profile_name`, ""),
      mobile_number: getValue(item, `wa_id`, ""),
    });
    if (
      getValue(item, `unreadCount`, "") > 0 &&
      getValue(item, `last_msg_id`, "")
    ) {
      handleReadMessage(
        item,
        getValue(item, `id`, ""),
        getValue(item, `last_msg_id`, "")
      );
    } else {
      if (selectedConversation !== getValue(item, `id`, "")) {
        setSelectedConversation(getValue(item, `id`, ""));
        getSpecificConversation(true, getValue(item, `id`, ""), 1);
        if (getValue(item, `contact_id`, "")) {
          setSelectedModule("contacts");
          getSpecificContactInfo(getValue(item, `contact_id`, ""));
        } else if (getValue(item, `lead_id`, "")) {
          setSelectedModule("leads");
          getSpecificLeadInfo(getValue(item, `lead_id`, ""));
        }
      }
    }
  };
  const handleSendMessages = async () => {
    if (message) {
      try {
        let resp = await sendWhatsappConversation(
          selectedConversation,
          removeNullOrUndefinedProperties({
            msgType: "text",
            textBody: replacePlaceholders(message),
            replyMsgId: getValue(selectedReplyMessage, `id`, ""),
          })
        );
        if (resp) {
          // setMessage("");
          setSelectedReplyMessage(null);
          setSelectedConversation(selectedConversation);
          getData(false, 1, "", conversationStatus);
          setConversation([]);
          getSpecificConversation(false, selectedConversation, 1);
        }
      } catch (error) {}
    }
  };
  const findType = (type: string) => {
    switch (type) {
      case ".png":
      case ".jpg":
      case ".jpeg":
      case ".svg":
        return "image";
      case ".mp4":
        return "video";
      case ".mp3":
        return "audio";
      case ".docx":
      case ".pdf":
        return "document";
      default:
        return "image";
    }
  };
  const [isSendLoading, setIsSendLoading] = useState(false);

  const handleSendDocumentMessages = async () => {
    setIsSendLoading(true);
    try {
      await Promise.all(
        documentRequest.map((item: object, index: number) => {
          return triggerAPI(
            {
              msgType: findType(getValue(item, `fileId.extension`, "")),
              textBody: getValue(item, `textBody`, ""),
              fileId: getValue(item, `fileId.id`, ""),
            },
            index
          );
        })
      );
    } catch (error) {
      console.error("Error sending document messages:", error);
    } finally {
      setIsSendLoading(false);
    }
  };
  const triggerAPI = async (obj: object, index: number) => {
    try {
      let resp = await sendWhatsappConversation(selectedConversation, obj);
      if (resp) {
        if (index == 0) {
          setDocumentRequest([]);
          setDocExpanded(false);
          getSpecificConversation(false, selectedConversation, 1);
          setSelectedReplyMessage(null);
        }
      }
    } catch (error) {}
  };
  /* -------------------------------------------------------------------------- */
  /*                                   Chat section                             */
  /* -------------------------------------------------------------------------- */
  const emojiPickerRef = useRef<any>(null);
  useEffect(() => {
    // Function to handle click outside
    const handleClickOutside = (event: any) => {
      if (
        emojiPickerRef.current &&
        !emojiPickerRef.current.contains(event.target)
      ) {
        setShowEmojiPicker(false);
      }
    };
    // Attach the event listener on mount
    document.addEventListener("mousedown", handleClickOutside);
    // Detach the event listener when component unmounts
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [emojiPickerRef]);

  const toggleEmojiPicker = () => {
    setShowEmojiPicker(!showEmojiPicker);
  };
  const ref = useRef<any>();
  const [message, setMessage] = useState<any>("<p></p>\n");
  const handleEmojiClick = (event: any, emojiObject: any) => {
    if (ref.current) {
      const cursor = ref.current.selectionStart;
      const newText =
        message.slice(0, cursor) + event.emoji + message.slice(cursor);
      setMessage(newText);
      toggleEmojiPicker();
    }
  };

  const [documentRequest, setDocumentRequest] = useState<any>([]);
  const [uploadLoading, setUploadLoading] = useState(false);
  const handleUploadDocument = (e: any) => {
    Array.from(e.target.files).forEach(async (item: any) => {
      let formData = new FormData();
      formData.append("file", item);
      formData.append("filename", item.name);
      formData.append("is_public", "true");
      formData.append("collection", "public-images");
      try {
        setUploadLoading(true);
        let resp = await mediaUpload(formData);
        if (resp) {
          setDocExpanded(true);
          documentRequest.push({
            fileId: getValue(resp, `data`, {}),
          });
          setDocumentRequest([...documentRequest]);
          setUploadLoading(false);
        } else {
          setUploadLoading(false);
        }
      } catch (error) {
        setUploadLoading(false);
      }
    });
  };

  const [selectedReplyMessage, setSelectedReplyMessage] = useState<any>(null);
  const handleReadMessage = async (
    item: any,
    conversationId: string,
    messageId: string
  ) => {
    try {
      let resp = await SpecificWhatsappConversationRead(
        conversationId
        // messageId
      );
      if (resp) {
        setSelectedConversation(getValue(item, `id`, ""));
        getSpecificConversation(false, getValue(item, `id`, ""), 1);
        if (getValue(item, `contact_id`, "")) {
          setSelectedModule("contacts");
          getSpecificContactInfo(getValue(item, `contact_id`, ""));
        } else if (getValue(item, `lead_id`, "")) {
          setSelectedModule("leads");
          getSpecificLeadInfo(getValue(item, `lead_id`, ""));
        } else {
          setContactInfo({});
          setColumnsList([]);
          setColumnLabelInfo({});
        }
        getAllCount();
        getData(false, 1, "", conversationStatus);
        // let payload = {
        //   ...UrlParams,
        //   status: false,
        //   page_no: page_no,
        //   conversationId: getValue(item, `id`, ""),
        //   searchText: query,
        //   convStatus: conversationStatus,
        //   userId: "",
        // };
        // let queryRequest = QueryRequestHelper(payload);
        // navigate(`${window.location.pathname}?${queryRequest}`);
      }
    } catch (error) {}
  };

  /* -------------------------------------------------------------------------- */
  /*                                  Contact Section                           */
  /* -------------------------------------------------------------------------- */

  const [contactInfo, setContactInfo] = useState({});
  const [columnList, setColumnsList] = useState([]);
  const [columnLabelInfo, setColumnLabelInfo] = useState({});
  const getSpecificContactInfo = async (id: string) => {
    if (id)
      try {
        let resp = await getSpecificContact(id);
        if (resp) {
          setContactInfo(getValue(resp, `data`, {}));
          if (getValue(resp, `data.pipeline_id`, "")) {
            let columnsList = await getSpecificPipelineColumns(
              await selectedModuleId("contacts"),
              getValue(resp, `data.pipeline_id`, "")
            );
            setColumnsList(
              getValue(columnsList, `data`, []).map((item: object) =>
                getValue(item, `api_name`, "")
              )
            );

            const obj: any = {};
            getValue(columnsList, `data`, []).forEach(
              (value: object, index: number) => {
                obj[getValue(value, `api_name`, "")] = getValue(
                  value,
                  `label`,
                  ""
                );
              }
            );
            setColumnLabelInfo(obj);
          }
        }
      } catch (error) {}
  };
  const getSpecificLeadInfo = async (id: string) => {
    if (id)
      try {
        let resp = await getSpecificLead(id);
        if (resp) {
          setContactInfo(getValue(resp, `data`, {}));
          if (getValue(resp, `data.pipeline_id`, "")) {
            let columnsList = await getSpecificPipelineColumns(
              await selectedModuleId("leads"),
              getValue(resp, `data.pipeline_id`, "")
            );
            setColumnsList(
              getValue(columnsList, `data`, []).map((item: object) =>
                getValue(item, `api_name`, "")
              )
            );

            const obj: any = {};
            getValue(columnsList, `data`, []).forEach(
              (value: object, index: number) => {
                obj[getValue(value, `api_name`, "")] = getValue(
                  value,
                  `label`,
                  ""
                );
              }
            );
            setColumnLabelInfo(obj);
          }
        }
      } catch (error) {}
  };

  /* -------------------------------------------------------------------------- */
  /*                            Form Creation Section                           */
  /* -------------------------------------------------------------------------- */
  const [staticFieldRequest, setStaticFieldRequest] = useState<any>({
    pipeline_id: "",
    stage: "",
  });
  const [options, setOptions] = useState([]);
  const [optionLoading, setOptionLoading] = useState(false);
  const getAllPipelines = async (module: string) => {
    try {
      setOptionLoading(true);
      let payload = {
        module_name: module,
      };
      let queryRequest = QueryRequestHelper(payload);
      let resp = await allPipelines(
        await selectedModuleId(module),
        queryRequest
      );
      if (resp) {
        let list =
          getValue(resp, `data.length`, 0) > 0
            ? getValue(resp, `data`, []).map((item: object) => ({
                ...item,
                value: getValue(item, `api_name`, ""),
                label: getValue(item, `label`, ""),
              }))
            : [];

        //**----------------------- creating pipeline options---------------- */
        setOptions(list);
        setOptionLoading(false);
      } else {
        setOptionLoading(false);
      }
    } catch (error) {
      setOptionLoading(false);
    }
  };
  const [stages, setStages] = useState([]);
  const [formFields, setFormFields] = useState<any>([]);
  const [isFormLoading, setIsFormLoading] = useState(false);
  const getFormFields = async (id: string) => {
    try {
      setIsFormLoading(true);
      let resp = await getSpecificPipeline(
        await selectedModuleId("contacts"),
        id
      );
      if (resp) {
        let stages =
          getValue(resp, `data.stages.length`, 0) > 0
            ? getValue(resp, `data.stages`, []).map((item: object) => ({
                ...item,
                value: getValue(item, `api_name`, ""),
                label: getValue(item, `label`, ""),
              }))
            : [];
        setStaticFieldRequest({
          ...staticFieldRequest,
          pipeline_id: getValue(resp, `data.id`, ""),
          stage: getValue(stages, `[${0}].id`, ""),
        });
        setStages(stages);

        let fields =
          getValue(resp, `data.form_fields.length`, 0) > 0
            ? getValue(resp, `data.form_fields`, []).map((item: object) => ({
                ...getValue(item, `module_field`, {}),
                name: getValue(item, `api_name`, ""),
                quick_add: getValue(item, `quick_add`, ""),
                required: getValue(item, `required`, ""),
                seq_num: getValue(item, `seq_num`, ""),
              }))
            : [];
        for (const item of fields) {
          if (item.api_name === "owner_id") {
            item.value = userId;
          }
        }
        let array: any = appendObjectValuesToArray(
          fields,
          selectedConversationInfo
        );

        setFormFields(removeDuplicatesById(array));

        setIsFormLoading(false);
      } else {
        setIsFormLoading(false);
      }
    } catch (error) {
      setIsFormLoading(false);
    }
  };
  /* -------------------------------------------------------------------------- */
  /*                              bottom chat Scroll                            */
  /* -------------------------------------------------------------------------- */
  const messagesEndRef = useRef<null | HTMLDivElement>(null);
  const scrollToBottom = () => {
    // messagesEndRef.current?.scrollIntoView({ behavior: "smooth" });
    messagesEndRef.current?.scrollIntoView({ behavior: "auto", block: "end" });
  };
  useEffect(() => {
    scrollToBottom();
  }, [getValue(conversation, `msgs`, [])]);

  /* -------------------------------------------------------------------------- */
  /*                                   Chat section                             */
  /* -------------------------------------------------------------------------- */
  const [query, setQuery] = useState("");
  const handleChangeSearch = (e: any) => {
    setQuery(e.target.value);
    let payload = {
      ...UrlParams,
      status: false,
      page_no: page_no,
      conversationId: selectedConversation,
      searchText: e.target.value,
      convStatus: conversationStatus,
      userId: "",
    };
    let queryRequest = QueryRequestHelper(payload);
    navigate(`${window.location.pathname}?${queryRequest}`);
  };

  const maxLength = 260;

  const [userDetails, setUserDetails] = useState(false);
  const toggleUserDetails = (module: string) => {
    resetPipelineForm();
    setSelectedType(module);
    getAllPipelines(module);
    setUserDetails(!userDetails);
  };

  const handleAddContact = () => {
    // getAllPipelines(module)
    resetPipelineForm();
    handleToggleWidth();
    setUserDetails(false);
  };

  const resetPipelineForm = () => {
    setSelectedType("");
    setStaticFieldRequest({
      pipeline_id: "",
      stage: "",
    });
    setFormFields([]);
  };
  /* -------------------------------------------------------------------------- */
  /*                               OrgUsers section                             */
  /* -------------------------------------------------------------------------- */
  const [orgUsers, setOrgUsers] = useState([]);
  const [loading, setLoading] = useState(false);
  const getActiveUsersData = async () => {
    if (getValue(orgUsers, `length`, 0) === 0) {
      let payload = {
        page_size: 100,
        page_no: 1,
      };
      let queryRequest = QueryRequestHelper(payload);
      try {
        setLoading(true);
        let resp = await getOrganisationUsers(queryRequest);
        if (resp) {
          setOrgUsers(
            getValue(resp, `data.length`, 0) > 0 &&
              getValue(resp, `data`, []).map((item: object) => ({
                ...item,
                value: getValue(item, `id`, ""),
                name:
                  getValue(item, `first_name`, "") +
                  " " +
                  getValue(item, `last_name`, ""),
                label:
                  getValue(item, `first_name`, "") +
                  " " +
                  getValue(item, `last_name`, ""),
              }))
          );
          setTotalCount(getValue(resp, `data.pagination.total`, 0));
          setLoading(false);
        } else {
          setLoading(false);
        }
      } catch (error) {
        setLoading(false);
      }
    }
  };

  const navigate = useNavigate();
  const handleClickFilter = (type: string, id: string) => {
    setContactInfo({});
    setSelectedContact({});
    setSelectedConversation("");
    delete UrlParams.conversationId;
    delete UrlParams.searchText;
    let payload = {
      ...UrlParams,

      page_no: 1,
      conversationId: "",
      searchText: "",
      convStatus: type,
      userId: id,
    };
    let queryRequest = QueryRequestHelper(payload);
    navigate(`${window.location.pathname}?${queryRequest}`);
  };

  /* -------------------------------------------------------------------------- */
  /*                               Whatsapp Channel                             */
  /* -------------------------------------------------------------------------- */
  const [isOpenFilterView, setIsOpenFilterView] = useState(false);
  const toggleFilterView = () => {
    setIsOpenFilterView(!isOpenFilterView);
  };

  const [filterRequest, setFilterRequest] = useState({
    onlyUnread: false,
    waAccountIds: [],
  });
  const [allConnectionList, setAllConnectionList] = useState([]);
  const getConnectedWhatsappList = async () => {
    try {
      let payload = {};
      let queryRequest = QueryRequestHelper(payload);
      let resp = await getAvailableConfigList(queryRequest);
      if (resp) {
        if (getValue(resp, `data.length`, 0) > 0) {
          setIsWAConfigured(true);
          setAllConnectionList(
            getValue(resp, `data`, []).map((item: object) => ({
              ...item,
              label:
                getValue(item, `verifiedName`, "") +
                " ( " +
                getValue(item, `displayPhoneNumber`, "") +
                " )",
              value: getValue(item, `id`, ""),
            }))
          );
        }
      }
    } catch (error) {}
  };

  const handleApplyFilter = () => {
    if (getValue(filterRequest, `onlyUnread`, false)) {
      delete UrlParams.onlyUnread;
    }
    delete UrlParams["waAccountIds[]"];
    let payload = {
      ...UrlParams,
      waAccountIds: getValue(filterRequest, `waAccountIds`, []),
      onlyUnread: getValue(filterRequest, `onlyUnread`, false),
    };
    let queryRequest = QueryRequestHelper(payload);
    navigate(`${window.location.pathname}?${queryRequest}`);
    toggleFilterView();
  };

  const handleResetFilter = () => {
    setFilterRequest({
      ...filterRequest,
      onlyUnread: false,
    });
    setFilterRequest((prevState: any) => ({
      ...prevState,
      waAccountIds: [],
    }));
    delete UrlParams.onlyUnread;
    delete UrlParams.waAccountIds;
    getData(true, page_no, query, conversationStatus);
    navigate(`/${getValue(params, "orgId", "")}/inbox`);
    toggleFilterView();
  };

  const [chatbotList, setChatbotList] = useState([]);
  const getChatbotData = async () => {
    try {
      setIsLoading(true);
      let payload = {
        page_size: 100,
        page_no: 1,
        platform: "WHATSAPP",
      };
      let queryRequest = QueryRequestHelper(payload);
      let resp = await getAllChatbots(queryRequest);
      if (resp) {
        setChatbotList(
          getValue(resp, `data.chatbots`, []).map((item: object) => ({
            ...item,
            label: getValue(item, `name`, ""),
            value: getValue(item, `id`, ""),
          }))
        );
        setIsLoading(false);
      } else {
        setIsLoading(false);
      }
    } catch (error) {
      setIsLoading(false);
    }
  };
  useDynamicTitle("Inbox");

  const [selectedType, setSelectedType] = useState("");

  const isLeadShow = () => {
    let info = modules.find(
      (item: object) => getValue(item, `api_name`, "") === "leads"
    );
    return getValue(info, `api_name`, "");
  };
  return (
    <div>
      <HomeHeader />
      <>
        {isLoading ? (
          <ListLoader />
        ) : isWAConfigured === false ? (
          <div className="d-flex flex-column manageFieldRight justify-content-center">
            <InboxNoDataPage />
          </div>
        ) : (
          <div className="chatbox-wrapper">
            <div className="chatbox-container">
              <div className="chatbox-left-container">
                <div className="contact-search-wrapper">
                  <div className="message_search">
                    <input
                      type="search"
                      name="search-form"
                      id="search-form"
                      className="contact-search"
                      onChange={(e) => handleChangeSearch(e)}
                      placeholder="Search user"
                    />
                    <div className="search-icon">
                      <SearchOutlined />
                    </div>
                    <Tooltip title={"Filter"}>
                      <div
                        className="cursor-pointer"
                        onClick={toggleFilterView}
                      >
                        <FilterSvgComponent size="22" />
                      </div>
                    </Tooltip>
                  </div>
                  <ChatFilter
                    handleClickFilter={handleClickFilter}
                    UrlParams={UrlParams}
                    userId={userId}
                    unreadCount={unreadCount}
                    unassignedCount={unassignedCount}
                    assignedCount={assignedCount}
                    myCount={myCount}
                    resolvedCount={resolvedCount}
                  />
                </div>
                <InboxContacts
                  isLoading={isLoading}
                  conversations={conversations}
                  selectedConversation={selectedConversation}
                  handleSelectConvesation={handleSelectConvesation}
                  handleRemovePipelineInfo={handleRemovePipelineInfo}
                  query={query}
                />
                {totalCount > 100 && (
                  <Pagination
                    totalCount={totalCount}
                    limit={page_size}
                    page_no={page_no}
                    handleChangePagination={handleChangePagination}
                    showLimit={"false"}
                    // handleChangeLimit={handleChangeLimit}
                  />
                )}
              </div>
              <div className="chatbox-center-container">
                {selectedConversation ? (
                  <div className="conversation-wrapper">
                    <div className="conversation-section">
                      <ChatRightHeader
                        handleToggleWidth={handleToggleWidth}
                        selectedContact={selectedContact}
                        selectedConversationInfo={selectedConversationInfo}
                        orgUsers={orgUsers}
                        getData={getDataStatusRefetch}
                        handleClickFilter={handleClickFilter}
                        chatbotList={chatbotList}
                      />
                      <div className="message-section">
                        {conversationLoading ? (
                          <Loader small />
                        ) : (
                          <>
                            <div className="chat-section">
                              {
                                <div className="d-flex justify-content-center">
                                  <div className="new-contact-preview">
                                    <AccountCircleSvgComponent
                                      color={"#dfe5e7"}
                                      size={100}
                                    />
                                    <p className="small_text__18 color_black text-center mb-1 mt-2">
                                      {getValue(selectedContact, "wa_id", "")}
                                    </p>
                                    {getValue(contactInfo, `first_name`, "") ||
                                    getValue(contactInfo, `last_name`, "") ? (
                                      <p
                                        className="small_text__14 text-center"
                                        style={{ textAlign: "left" }}
                                      >
                                        ~
                                        {formatString(
                                          getValue(
                                            contactInfo,
                                            `first_name`,
                                            ""
                                          )
                                        )}{" "}
                                        {formatString(
                                          getValue(contactInfo, `last_name`, "")
                                        )}
                                      </p>
                                    ) : (
                                      <p
                                        className="small_text__14 text-center"
                                        style={{ textAlign: "left" }}
                                      >
                                        ~
                                        {getValue(
                                          selectedContact,
                                          `wa_profile_name`,
                                          ""
                                        )
                                          ? formatString(
                                              getValue(
                                                selectedContact,
                                                `wa_profile_name`,
                                                ""
                                              )
                                            )
                                          : getValue(
                                              selectedContact,
                                              `first_name`,
                                              ""
                                            ) ||
                                            getValue(
                                              selectedContact,
                                              `last_name`,
                                              ""
                                            )
                                          ? formatString(
                                              getValue(
                                                selectedContact,
                                                `first_name`,
                                                ""
                                              )
                                            )
                                          : ""}
                                      </p>
                                    )}
                                    {!getValue(contactInfo, `id`, "") ? (
                                      <>
                                        <p className="small_text__13 text-center mb-1 mt-2">
                                          Not a contact- No groups in common
                                        </p>
                                        <Space>
                                          <Button
                                            size="middle"
                                            type="primary"
                                            onClick={() => handleAddContact()}
                                            className="mt-2"
                                          >
                                            Add to{" "}
                                            {isLeadShow() ? "Leads/" : ""}
                                            Contacts
                                          </Button>
                                        </Space>
                                      </>
                                    ) : (
                                      <>
                                        <p className="small_text__13 text-center mb-1 mt-2">
                                          The contact already associated to{" "}
                                          {selectedModule}
                                        </p>
                                      </>
                                    )}
                                  </div>
                                </div>
                              }
                              <div
                                id="scrollableDiv"
                                style={{
                                  height: "500px",
                                  overflow: "auto",
                                  display: "flex",
                                  flexDirection: "column-reverse",
                                }}
                              >
                                <InfiniteScroll
                                  dataLength={conversation.length}
                                  next={fetchMoreMessages}
                                  hasMore={conversation.length < m_total_count}
                                  loader={
                                    conversationLoading ? (
                                      <Loader small />
                                    ) : null
                                  }
                                  style={{
                                    display: "flex",
                                    flexDirection: "column-reverse",
                                  }}
                                  inverse={true} // Scroll from bottom to top
                                  scrollableTarget="scrollableDiv"
                                >
                                  <ConversationMessages
                                    conversation={conversation}
                                    setSelectedReplyMessage={
                                      setSelectedReplyMessage
                                    }
                                  />
                                </InfiniteScroll>
                              </div>

                              <div ref={messagesEndRef} />
                            </div>
                          </>
                        )}
                        {selectedReplyMessage && (
                          <motion.div
                            initial={{ y: 0, opacity: 0 }}
                            animate={{ y: 0, opacity: 1 }}
                            transition={{ duration: 0.3, ease: "easeInOut" }}
                          >
                            <div className="d-flex  align-items-center replay-message-wrapper">
                              <div className="reply_message_container d-flex justify-content-between align-items-center px-4 py-2 mx-4 mt-2 w-100 ">
                                <div className="d-flex align-items-center ms-2">
                                  <DocumentSvgComponent />
                                  <p className="small_text__14 ms-2">
                                    {getValue(
                                      selectedReplyMessage,
                                      `media_caption`,
                                      ""
                                    )
                                      ? getValue(
                                          selectedReplyMessage,
                                          `media_caption`,
                                          ""
                                        ).length > maxLength
                                        ? getValue(
                                            selectedReplyMessage,
                                            `media_caption`,
                                            ""
                                          ).substring(0, maxLength) + "..."
                                        : getValue(
                                            selectedReplyMessage,
                                            `media_caption`,
                                            ""
                                          )
                                      : getValue(
                                          selectedReplyMessage,
                                          `text_body`,
                                          ""
                                        )
                                      ? getValue(
                                          selectedReplyMessage,
                                          `text_body`,
                                          ""
                                        ).length > maxLength
                                        ? getValue(
                                            selectedReplyMessage,
                                            `text_body`,
                                            ""
                                          ).substring(0, maxLength) + "..."
                                        : getValue(
                                            selectedReplyMessage,
                                            `text_body`,
                                            ""
                                          )
                                      : getValue(
                                          selectedReplyMessage,
                                          `upload_file.filename`,
                                          ""
                                        )
                                      ? getValue(
                                          selectedReplyMessage,
                                          `upload_file.filename`,
                                          ""
                                        ).length > maxLength
                                        ? getValue(
                                            selectedReplyMessage,
                                            `upload_file.filename`,
                                            ""
                                          ).substring(0, maxLength) + "..."
                                        : getValue(
                                            selectedReplyMessage,
                                            `upload_file.filename`,
                                            ""
                                          )
                                      : getValue(
                                          selectedReplyMessage,
                                          `template.body`,
                                          ""
                                        )
                                      ? getValue(
                                          selectedReplyMessage,
                                          `template.body`,
                                          ""
                                        ).length > maxLength
                                        ? getValue(
                                            selectedReplyMessage,
                                            `template.body`,
                                            ""
                                          ).substring(0, maxLength) + "..."
                                        : getValue(
                                            selectedReplyMessage,
                                            `template.body`,
                                            ""
                                          )
                                      : getValue(
                                          selectedReplyMessage,
                                          `type`,
                                          ""
                                        )
                                      ? getValue(
                                          selectedReplyMessage,
                                          `type`,
                                          ""
                                        )
                                      : "No"}
                                  </p>
                                </div>
                              </div>
                              <div
                                onClick={() => setSelectedReplyMessage(null)}
                                className="cursor-pointer pe-3"
                              >
                                <CrossSvgComponent />
                              </div>
                            </div>
                          </motion.div>
                        )}
                        <ChatFooter
                          showEmojiPicker={showEmojiPicker}
                          handleEmojiClick={handleEmojiClick}
                          toggleEmojiPicker={toggleEmojiPicker}
                          emojiPickerRef={emojiPickerRef}
                          message={message}
                          setMessage={setMessage}
                          reference={ref}
                          handleSendMessages={handleSendMessages}
                          handleUploadDocument={handleUploadDocument}
                          selectedConversationInfo={selectedConversationInfo}
                        />
                      </div>
                    </div>

                    <div
                      className={`document-review-section  ${
                        docExpanded ? "expanded" : ""
                      }`}
                    >
                      <div className={`${docExpanded ? "show" : "hidden"}`}>
                        <ChatDocumentPreview
                          showEmojiPicker={showEmojiPicker}
                          handleEmojiClick={handleEmojiClick}
                          toggleEmojiPicker={toggleEmojiPicker}
                          message={message}
                          setMessage={setMessage}
                          reference={ref}
                          handleSendMessages={handleSendMessages}
                          setDocExpanded={setDocExpanded}
                          docExpanded={docExpanded}
                          handleUploadDocument={handleUploadDocument}
                          documentRequest={documentRequest}
                          setDocumentRequest={setDocumentRequest}
                          handleSendDocumentMessages={
                            handleSendDocumentMessages
                          }
                          isSendLoading={isSendLoading}
                        />
                      </div>
                    </div>
                    <motion.div
                      key="otherComponent"
                      initial={{ x: "100%", opacity: 1 }}
                      animate={{ x: 0, opacity: 1 }}
                      exit={{ x: "-100%", opacity: 1 }}
                      transition={{ duration: 0.5 }}
                      className={`profile-section ${
                        expanded ? "expanded" : ""
                      }`}
                    >
                      <div className={`${expanded ? "show" : "hidden"}`}>
                        <ChatContactInfo
                          handleToggleWidth={handleToggleWidth}
                          contactInfo={contactInfo}
                          columnList={columnList}
                          columnLabelInfo={columnLabelInfo}
                          allPipelines={options}
                          stages={stages}
                          fields={formFields}
                          selectedConversation={getValue(
                            selectedConversationInfo,
                            `id`,
                            ""
                          )}
                          setFields={setFormFields}
                          getFormFields={getFormFields}
                          isFormLoading={isFormLoading}
                          staticFieldRequest={staticFieldRequest}
                          setStaticFieldRequest={setStaticFieldRequest}
                          selectedContact={selectedContact}
                          userDetails={userDetails}
                          toggleUserDetails={toggleUserDetails}
                          setSelectedType={setSelectedType}
                          selectedModule={selectedModule}
                          getSpecificLeadInfo={getSpecificLeadInfo}
                          getSpecificContactInfo={getSpecificContactInfo}
                          isLeadShow={isLeadShow}
                        />
                      </div>
                    </motion.div>
                  </div>
                ) : (
                  <div className="no-conversation">
                    <img src="/nodata/messages.svg" className="whatsapp_no" />
                    <h4 className="mt-3">No Conversation Selected</h4>
                    <p>Select a conversation to read</p>
                  </div>
                )}
              </div>
            </div>
          </div>
        )}
      </>
      {/* )} */}
      <WaAdvancefilter
        isOpenFilterView={isOpenFilterView}
        toggleFilterView={toggleFilterView}
        allConnectionList={allConnectionList}
        filterRequest={filterRequest}
        setFilterRequest={setFilterRequest}
        handleResetFilter={handleResetFilter}
        handleApplyFilter={handleApplyFilter}
      />
    </div>
  );
}

export default Messages;
