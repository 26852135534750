import ContactPipelineDetails from "@components/Pages/Pipeline/Detail/contacts";
import LeadPipelineDetails from "@components/Pages/Pipeline/Detail/leads";
import Contacts from "@pages/Private/Contacts";
import Leads from "@pages/Private/Leads";

export const LeadsRoutes = [
  {
    path: "/:orgId/crm/leads",
    name: "Leads",
    component: Leads,
  },
  {
    path: "/:orgId/crm/leads/detail/:id",
    name: "Leads",
    component: LeadPipelineDetails,
  },
];
