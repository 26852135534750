import ActivityCalls from "@pages/Private/Calls";
import CallsDetailSection from "@pages/Private/Calls/Detail";
import ActivityMeetings from "@pages/Private/Meetings";
import MeetingsDetailSection from "@pages/Private/Meetings/Detail";
import Tasks from "@pages/Private/Tasks";
import TaskDetailSection from "@pages/Private/Tasks/Detail";

export const TasksRoutes = [
  //tasks
  {
    path: "/:orgId/crm/tasks",
    name: "Tasks",
    component: Tasks,
  },
  {
    path: "/:orgId/crm/tasks/detail/:id",
    name: "Sales",
    component: TaskDetailSection,
  },
  //calls
  {
    path: "/:orgId/crm/calls",
    name: "Tasks",
    component: ActivityCalls,
  },
  {
    path: "/:orgId/crm/calls/detail/:id",
    name: "Sales",
    component: CallsDetailSection,
  },

  //meetings
  {
    path: "/:orgId/crm/meetings",
    name: "Tasks",
    component: ActivityMeetings,
  },
  {
    path: "/:orgId/crm/meetings/detail/:id",
    name: "Sales",
    component: MeetingsDetailSection,
  },
];
