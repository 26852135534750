// import { useParams, NavLink } from "react-router-dom";
// import "./Header.scss";
// import { getValue } from "@utils/lodash";
// import HeaderSignin from "../HeaderSignin/HeaderSignin";
// import Logo from "@components/common/Logo/Logo";
// import ChevronDown from "@assets/svg/chevron-down";
// import { getStartOfMonthDate } from "@common/date-helpers";
// import { useStateContext } from "@context/profileProvider";
// import { listAllModules } from "@services/modules.service";
// import { useEffect, useState } from "react";

// interface Module {
//   label_singular: string;
//   label: string;
//   link: string;
// }

// export default function HomeHeader() {
//   const params = useParams();
//   const { newNotification } = useStateContext();
//   let date_range = getStartOfMonthDate();
//   const navItems = [
//     {
//       name: "Dashboard",
//       link: `${
//         getValue(params, `orgId`, "")
//           ? `/${getValue(
//               params,
//               `orgId`,
//               ""
//             )}/dashboard?date_range=${date_range}`
//           : "#"
//       }`,
//       isNewFeature: false,
//     },
//     {
//       name: "CRM",
//       link: `/${getValue(params, "orgId", "")}/crm`,
//       isDropdown: true,
//       isNewFeature: false,
//     },
//     {
//       name: "Broadcast",
//       link: `/${getValue(params, "orgId", "")}/broadcast`,
//       isNewFeature: true,
//     },
//     { name: "Inbox", link: `/${getValue(params, "orgId", "")}/inbox` },
//     {
//       name: "Bots",
//       link: `/${getValue(params, "orgId", "")}/chatbot`,
//       isNewFeature: true,
//     },
//     {
//       name: "Properties",
//       link: `/${getValue(params, "orgId", "")}/property`,
//       isNewFeature: false,
//     },
//   ];

//   const [allModules, setAllModules] = useState<Module[]>([]);

//   useEffect(() => {
//     getModules();
//   }, []);

//   const getModules = async () => {
//     let resp = await listAllModules("");
//     if (resp) {
//       const updatedModules = resp.data.map((module: any) => {
//         let link = "";
//         const orgId = getValue(params, "orgId", "");
//         switch (module.label_singular) {
//           case "Contact":
//             link = `/${orgId}/crm/contacts`;
//             break;
//           case "Company":
//             link = `/${orgId}/crm/companies`;
//             break;
//           case "Deal":
//             link = `/${orgId}/crm/deals`;
//             break;
//           case "Task":
//             link = `/${orgId}/crm/tasks`;
//             break;
//           case "Call":
//             link = `/${orgId}/crm/calls`;
//             break;
//           case "Meeting":
//             link = `/${orgId}/crm/meetings`;
//             break;
//           default:
//             link = `/${orgId}/crm`;
//             break;
//         }
//         return { ...module, link };
//       });
//       setAllModules(updatedModules);
//     }
//   };

//   return (
//     <header className={"header-wrapper"}>
//       <div className={`d-flex align-items-center`}>
//         {/* <Logo /> */}
//         <nav className="navbar_items__wrapper">
//           {navItems.map((item, index) => (
//             <div key={index} className="navbar_item">
//               {item.isDropdown ? (
//                 <div className="navbar_dropdown_container">
//                   <NavLink
//                     to={item.link}
//                     className={({ isActive }) =>
//                       `navbar_nav__link ${isActive ? "navlink_active" : ""}`
//                     }
//                   >
//                     {item.name}
//                     <div className="chevron-icon">
//                       <ChevronDown color="#fff" size="12" />
//                     </div>
//                   </NavLink>
//                   <div className="navbar_dropdown_menu">
//                     {allModules.map((dropdownItem, index) => (
//                       <>
//                         <NavLink
//                           to={dropdownItem.link}
//                           key={index}
//                           className={({ isActive }) =>
//                             `subnav_dropdown_item ${
//                               isActive ? "subnav_dropdown_item_active" : ""
//                             }`
//                           }
//                         >
//                           {dropdownItem.label}
//                         </NavLink>
//                         {index === 2 && (
//                           <div className="d-flex justify-content-center">
//                             <hr className="navbar-seperator"></hr>
//                           </div>
//                         )}
//                       </>
//                     ))}
//                   </div>
//                 </div>
//               ) : (
//                 <NavLink
//                   to={item.link}
//                   className={({ isActive }) =>
//                     `navbar_nav__link ${isActive ? "navlink_active" : ""} `
//                   }
//                 >
//                   {item.name}
//                   {newNotification === true && (
//                     <>
//                       {item.name === "Inbox" && (
//                         <div className="msg-header-notification"></div>
//                       )}
//                     </>
//                   )}
//                   {item.isNewFeature && (
//                     <div className="new-feature-tag">New</div>
//                   )}
//                 </NavLink>
//               )}
//             </div>
//           ))}
//         </nav>
//       </div>
//       <HeaderSignin />
//     </header>
//   );
// }
import { useParams, NavLink, useLocation } from "react-router-dom";
import "./Header.scss";
import { getValue } from "@utils/lodash";
import HeaderSignin from "../HeaderSignin/HeaderSignin";
import { getStartOfMonthDate } from "@common/date-helpers";
import { useStateContext } from "@context/profileProvider";
import { Menu, Dropdown, Badge } from "antd";
import { CaretDownOutlined, DownOutlined } from "@ant-design/icons";
import Logo from "@components/common/Logo/Logo";
import ChevronDown from "@assets/svg/chevron-down";

interface Module {
  label_singular: string;
  label: string;
  link: string;
  type: string;
  linkText: string;
  linkUrl: string;
  id: string;
}

export default function HomeHeader(props: any) {
  const { setOpenTour, tourRef1 } = props;
  const params = useParams();
  const location = useLocation();
  const { newNotification, modules, totalInboxCount, currentCRMUrl } =
    useStateContext();
  let date_range = getStartOfMonthDate();

  const navItems = [
    {
      name: "Dashboard",
      link: `${
        getValue(params, `orgId`, "")
          ? `/${getValue(
              params,
              `orgId`,
              ""
            )}/dashboard?date_range=${date_range}`
          : "#"
      }`,
      isNewFeature: false,
    },
    {
      name: "CRM",
      link: `/${getValue(params, "orgId", "")}/crm/leads`,
      isDropdown: true,
      isNewFeature: false,
    },
    {
      name: "Broadcast",
      link: `/${getValue(params, "orgId", "")}/broadcast`,
      isNewFeature: true,
    },
    { name: "Inbox", link: `/${getValue(params, "orgId", "")}/inbox` },
    {
      name: "Bots",
      link: `/${getValue(params, "orgId", "")}/chatbot`,
      isNewFeature: true,
    },
    // {
    //   name: "Properties",
    //   link: `/${getValue(params, "orgId", "")}/property`,
    //   isNewFeature: false,
    // },
  ];

  const renderDropdownMenu = (items: Module[]) => {
    const pipelineItems = items.filter((item) => item.type === "PIPELINE");
    const activityItems = items.filter((item) => item.type === "ACTIVITY");
    return (
      <Menu className="navbar-dropdown-container">
        {pipelineItems.length > 0 && (
          <>
            <Menu.ItemGroup title="" className="navbar-dropdown-menu">
              {pipelineItems.map((dropdownItem) => {
                return (
                  <Menu.Item key={dropdownItem.id}>
                    <NavLink
                      to={dropdownItem.linkUrl}
                      className={`subnav_dropdown_item ${
                        location.pathname == dropdownItem.linkUrl
                          ? "active"
                          : ""
                      }`}
                    >
                      {dropdownItem.linkText}
                    </NavLink>
                  </Menu.Item>
                );
              })}
            </Menu.ItemGroup>
            <Menu.Divider className="navbar-dropwdown-divider" />
          </>
        )}
        {activityItems.length > 0 && (
          <Menu.ItemGroup className="navbar-dropdown-menu">
            {activityItems.map((dropdownItem) => (
              <Menu.Item key={dropdownItem.id}>
                <NavLink
                  to={dropdownItem.linkUrl}
                  className={`subnav_dropdown_item ${
                    location.pathname === dropdownItem.linkUrl ? "active" : ""
                  }`}
                >
                  {dropdownItem.linkText}
                </NavLink>
              </Menu.Item>
            ))}
          </Menu.ItemGroup>
        )}
      </Menu>
    );
  };

  return (
    <header className="header-wrapper">
      <div className="d-flex align-items-center">
        <Logo />
        <nav className="navbar_items__wrapper">
          {navItems.map((item: any, index) => {
            return (
              <div
                key={index}
                className="navbar_item"
                ref={item.name === "CRM" ? tourRef1 : null}
              >
                {item.isDropdown ? (
                  <Dropdown overlay={renderDropdownMenu(modules)}>
                    <NavLink
                      to={
                        getValue(item, `name`, "") === "CRM"
                          ? currentCRMUrl
                            ? currentCRMUrl
                            : item.link
                          : item.link
                      }
                      className={`navbar_nav__link ${
                        item.name === "CRM" && location.pathname == item.linkUrl
                          ? "active"
                          : ""
                      }`}
                      style={{
                        color: "#dadce0",
                        fontSize: "14px",
                        marginLeft: 8,
                      }}
                    >
                      <div className="me-2">{item.name}</div>
                      <ChevronDown color="#dadce0" size="12px" />
                    </NavLink>
                  </Dropdown>
                ) : (
                  <NavLink
                    to={item.link}
                    className={`navbar_nav__link ${
                      location.pathname == item.linkUrl ? "active" : ""
                    }`}
                  >
                    {item.name}
                    {item.name === "Inbox" && (
                      <>
                        {
                          // <Badge dot offset={[10, 0]} />

                          totalInboxCount() > 0 && (
                            <div className="whatsapp-msg-notification">
                              <Badge
                                color="#25d366"
                                count={totalInboxCount()}
                                size="small"
                              />
                            </div>
                          )
                        }
                      </>
                    )}
                    {item.isNewFeature && (
                      <div className="new-feature-tag">New</div>
                    )}
                  </NavLink>
                )}
              </div>
            );
          })}
        </nav>
      </div>
      <HeaderSignin setOpenTour={setOpenTour} />
    </header>
  );
}
