import React, { useState } from "react";
import { Tabs } from "antd";
import { getValue } from "@utils/lodash";
import { concatString, formatString } from "@common/text-helpers";
import { useStateContext } from "context/profileProvider";

const { TabPane } = Tabs;

function NavViews(props: any) {
  const { userId } = useStateContext();
  const urlSearchParams = new URLSearchParams(window.location.search);
  const params = Object.fromEntries(urlSearchParams.entries());
  let views = [
    {
      id: "",
      value: "",
      name: `All ${formatString(getValue(props, `plural_label`, ""))}`,
    },
    {
      id: userId,
      name: `My ${formatString(getValue(props, `plural_label`, ""))}`,
    },
    {
      id: "NO_OWNER",
      name: `Unassigned ${formatString(getValue(props, `plural_label`, ""))}`,
    },
  ];

  return (
    <div className="ms-4 mt-3">
      <Tabs
        activeKey={getValue(params, `filter_owner_id`, "")}
        onChange={(activeKey) => {
          props.handleNaviagteView("filter_owner_id", activeKey);
        }}
        type="card"
      >
        {getValue(props, `plural_label`, "") && getValue(views, `length`, 0) > 0
          ? views.map((item: any) => (
              <TabPane
                tab={
                  <p className="d-flex justify-content-between align-items-center">
                    {concatString(getValue(item, `name`, ""), 20)}
                  </p>
                }
                key={getValue(item, `id`, "")}
              />
            ))
          : null}
      </Tabs>
    </div>
  );
}

export default NavViews;
