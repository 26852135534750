import PipelineSubHeader from "@components/Pages/Pipeline/SubHeader";
import HomeHeader from "@components/common/Header/HomeHeader/Header";
import Loader from "@components/common/Loader/loading";
import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import BotTableData from "./components/botTableData";
import "./createbot.scss";
import { getValue } from "@utils/lodash";
import {
  cloneChatbot,
  deleteChatbot,
  getAllAvatar,
  getAllChatbots,
} from "@services/chatbot.service";
import { QueryRequestHelper } from "@common/query-request-helper";
import CompanyProfileNavigation from "@layouts/SettingsLayout/CompanyProfileNavigation";
import DeleteModal from "@components/Dialogs/Modals/deleteModal";
import { toast } from "sonner";
import Pagination from "@components/Pages/Pipeline/common/pagination";
import BotCloneConfirmModal from "./components/confirmModal";
import ListLoader from "@components/common/Loader";
import useDynamicTitle from "@context/useDynamicTitle";
import BotTypeModal from "./components/bot-type-modal";
import { param } from "lightgallery/plugins/video/lg-video-utils";

const BotsPage = () => {
  const params = useParams();
  const navigate = useNavigate();

  const urlSearchParams = new URLSearchParams(window.location.search);
  const UrlParams = Object.fromEntries(urlSearchParams.entries());
  /* -------------------------------------------------------------------------- */
  /*                               UseState Section                             */
  /* -------------------------------------------------------------------------- */
  const [isLoading, setIsLoading] = useState(false);
  const [list, setList] = useState([]);
  const [openBotTypeModal, setOpenBotTypeModal] = useState(false);

  const toggleBotTypeModal = () => {
    setOpenBotTypeModal(!openBotTypeModal);
  };

  useEffect(() => {
    if (Object.keys(UrlParams).length === 0) {
      getData();
      getAvatar();
    }
  }, []);
  useEffect(() => {
    if (Object.keys(UrlParams).length > 0) {
      if (UrlParams.page_no) {
        setPage_no(parseInt(UrlParams.page_no));
      }
      if (UrlParams.limit) {
        setLimit(parseInt(UrlParams.limit));
      }
      getData();
    }
  }, [window.location.href]);
  /* -------------------------------------------------------------------------- */
  /*                               API Section                                  */
  /* -------------------------------------------------------------------------- */
  const getAvatar = async () => {
    try {
      let resp = await getAllAvatar({});
      if (resp) {
      }
    } catch (error) {}
  };
  const getData = async () => {
    try {
      setIsLoading(true);
      let payload = {
        page_size: getValue(UrlParams, `limit`, "")
          ? getValue(UrlParams, `limit`, "")
          : limit,
        page_no: getValue(UrlParams, `page_no`, "")
          ? getValue(UrlParams, `page_no`, "")
          : page_no,
        search: getValue(UrlParams, `search`, ""),
      };
      let queryRequest = QueryRequestHelper(payload);
      let resp = await getAllChatbots(queryRequest);
      if (resp) {
        setList(getValue(resp, `data.chatbots`, []));
        setTotalCount(getValue(resp, `data.pagination.total`, 0));
        setIsLoading(false);
      } else {
        setIsLoading(false);
      }
    } catch (error) {
      setIsLoading(false);
    }
  };

  /* -------------------------------------------------------------------------- */
  /*                              Delete Section                                */
  /* -------------------------------------------------------------------------- */
  const [deleteId, setDeleteId] = useState("");
  const [deleteValue, setDeleteValue] = useState("");
  const [isOpen, setIsOpen] = useState(false);

  const handleModal = () => {
    setIsOpen(!isOpen);
  };

  const handleDeleteView = (id: string) => {
    setDeleteId(id);
    handleModal();
  };
  const handleDeleteBot = async () => {
    try {
      let resp = await deleteChatbot(deleteId);
      if (resp) {
        toast.success("Deleted Successfully");
        handleModal();
        setDeleteId("");
        getData();
      }
    } catch (error) {}
  };

  /* -------------------------------------------------------------------------- */
  /*                              Clone Section                                */
  /* -------------------------------------------------------------------------- */
  const [cloneId, setCloneID] = useState("");
  const [isOpen1, setIsOpen1] = useState(false);

  const handleModal1 = () => {
    setIsOpen1(!isOpen1);
  };

  const handleClone = (id: string) => {
    setCloneID(id);
    handleModal1();
  };
  const handleCloneBot = async () => {
    try {
      let resp = await cloneChatbot(cloneId);
      if (resp) {
        toast.success("Cloned Successfully");
        handleModal1();
        setCloneID("");
        getData();
      }
    } catch (error) {}
  };
  /* -------------------------------------------------------------------------- */
  /*                               Pagination section                           */
  /* -------------------------------------------------------------------------- */

  const [totalCount, setTotalCount] = useState(0);
  const [limit, setLimit] = useState(10);
  const [page_no, setPage_no] = useState(1);

  const handleChangePagination = (page_no: string) => {
    let payload = {
      ...UrlParams,
      page_no: page_no,
    };
    let queryRequest = QueryRequestHelper(payload);
    navigate(`${window.location.pathname}?${queryRequest}`);
  };

  const handleChangeLimit = (limit: string) => {
    let payload: any = {
      ...UrlParams,
      limit: limit,
      page_no: "1",
    };
    let queryRequest = QueryRequestHelper(payload);
    navigate(`${window.location.pathname}?${queryRequest}`);
  };
  useDynamicTitle("Chatbot");

  const [selectedBotType, setSelectedBotType] = useState("web");

  const handleChangeBotType = (e: any) => {
    setSelectedBotType(e.target.value);
  };

  const handleSelectBotType = () => {
    navigate(
      `/${getValue(params, "orgId", "")}/chatbot/builder/${selectedBotType}`
    );
  };
  return (
    <div>
      <HomeHeader />
      <PipelineSubHeader
        subHeaderListTitle={`Bots (${getValue(list, "length", "")})`}
        isRightArrowVisible={true}
        isSubmenuListAvailable={true}
        hideClose={true}
        pipeline={false}
      />
      <div className="d-flex">
        <div className="w-100">
          {/* {getValue(list, "length", "") < 1 && (
            <div className="other-content subheader_filter_section open">
              <div className="filter-wrapper d-flex align-items-center justify-content-between my-1 mx-4">
                <div className="d-flex gap-3 align-items-center">
                  <p>
                    Bots{" "}
                    <span className="form-label">
                      ({getValue(list, "length", "")})
                    </span>
                  </p>
                </div>
                <div className="d-flex gap-3">
                  <button
                    className=" create-user-button create-user-button--department hover-text touchable-text-white px-4 py-2"
                    onClick={() =>
                      navigate(
                        `/${getValue(params, `orgId`, "")}/chatbot/builder`
                      )
                    }
                    // onClick={handleModal}
                  >
                    Add
                  </button>
                </div>
              </div>
            </div>
          )} */}
          {isLoading ? (
            <ListLoader />
          ) : (
            <BotTableData
              list={list}
              handleDelete={handleDeleteView}
              handleClone={handleClone}
              toggleBotTypeModal={toggleBotTypeModal}
            />
          )}
          <Pagination
            totalCount={totalCount}
            limit={limit}
            page_no={page_no}
            handleChangePagination={handleChangePagination}
            handleChangeLimit={handleChangeLimit}
          />
        </div>
        <DeleteModal
          isOpen={isOpen}
          handleModal={handleModal}
          handleSubmit={handleDeleteBot}
          deleteValue={deleteValue}
        />
        <BotCloneConfirmModal
          isOpen={isOpen1}
          handleModal={handleModal1}
          handleSubmit={handleCloneBot}
        />
        <BotTypeModal
          isModalOpen={openBotTypeModal}
          handleCancel={toggleBotTypeModal}
          selectedBotType={selectedBotType}
          handleChangeBotType={handleChangeBotType}
          handleSelectBotType={handleSelectBotType}
        />
      </div>
    </div>
  );
};

export default BotsPage;
