import Broadcast from "@pages/Private/Broadcast";
import AdsPage from "@pages/Private/Settings/Ads";
import AdsInfo from "@pages/Private/Settings/Ads/AdsInfo";
import AdsLeadInfo from "@pages/Private/Settings/Ads/AdsLeadInfo";
import AdLeadInfoDetailPage from "@pages/Private/Settings/Ads/AdLeadInfoDetail";
import WhatsappCommunicationPages from "@pages/Private/Inbox";
import MessagePages from "@pages/Private/Settings/Messages";
import CreateTemplateMessage from "@pages/Private/Broadcast/CreateTemplateMessage";
import CreateBroadcast from "@pages/Private/Broadcast/createBroadcast";

export const MarketingRoutes = [
  {
    path: "/:orgId/broadcast",
    name: "Companies",
    component: Broadcast,
  },
  {
    path: "/:orgId/broadcast/create-broadcast",
    name: "Companies",
    component: CreateBroadcast,
  },
  {
    path: "/:orgId/broadcast/:id",
    name: "Companies",
    component: CreateBroadcast,
  },
  {
    path: "/:orgId/broadcast/template/:id",
    name: "Template",
    component: CreateTemplateMessage,
  },
  {
    path: "/:orgId/broadcast/create-template",
    name: "Template",
    component: CreateTemplateMessage,
  },
  {
    path: "/:orgId/settings/messages",
    name: "Companies",
    component: MessagePages,
  },
  {
    path: "/:orgId/settings/ads",
    name: "Ads",
    component: AdsPage,
  },
  {
    path: "/:orgId/settings/ads/:id",
    name: "Ads",
    component: AdsInfo,
  },
  {
    path: "/:orgId/settings/ads/lead-forms/:id",
    name: "Ads",
    component: AdsLeadInfo,
  },
  {
    path: "/:orgId/settings/ads/lead-forms/:id/add",
    name: "Ads",
    component: AdLeadInfoDetailPage,
  },
  {
    path: "/:orgId/settings/ads/lead-forms/:id/edit/:formId",
    name: "Ads",
    component: AdLeadInfoDetailPage,
  },
  {
    path: "/:orgId/inbox",
    name: "Ads",
    component: WhatsappCommunicationPages,
  },
];
