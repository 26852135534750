import CompanyPipelineDetails from "@components/Pages/Pipeline/Detail/companies";
import Companies from "@pages/Private/Companies";

export const CompaniesRoutes = [
  {
    path: "/:orgId/crm/companies",
    name: "Companies",
    component: Companies,
  },
  {
    path: "/:orgId/crm/companies/detail/:id",
    name: "Companies",
    component: CompanyPipelineDetails,
  },
];
