import ContactPipelineDetails from "@components/Pages/Pipeline/Detail/contacts";
import Contacts from "@pages/Private/Contacts";

export const ContactsRoutes = [
  {
    path: "/:orgId/crm/contacts",
    name: "Contacts",
    component: Contacts,
  },
  {
    path: "/:orgId/crm",
    name: "Contacts",
    component: Contacts,
  },
  {
    path: "/:orgId/crm/contacts/detail/:id",
    name: "Contacts",
    component: ContactPipelineDetails,
  },
];
