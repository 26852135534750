// import Settings from "@pages/Private/Settings";
import CompanyProfile from "@pages/Private/Settings/CompanyProfile";
import Branding from "@pages/Private/Settings/Branding";
import ModuleAndField from "@pages/Private/Settings/ModuleAndField";
import ManageFields from "@pages/Private/Settings/ModuleAndField/ManageFields/index";
import SettingsModuleFields from "@pages/Private/Settings/ModuleAndField/ManageFields/Fields";
import SettingsModuleStages from "@pages/Private/Settings/ModuleAndField/ManageFields/Stages";
import SettingsModuleInfo from "@pages/Private/Settings/ModuleAndField/ManageFields/Info";
import SettingUsers from "@pages/Private/Settings/Users";
import SettingsEmail from "@pages/Private/Settings/email/Email";
import SettingsEmailDeliverability from "@pages/Private/Settings/email/Deliverability";
import SettingsAddEmailDeliverability from "@pages/Private/Settings/email/Deliverability/add-sender";
import SettingsEmailTemplates from "@pages/Private/Settings/email/Templates";
import SettingsWorkflows from "@pages/Private/Settings/workflows";
import SettingsWorkflowDetail from "@pages/Private/Settings/workflows/create";
import SettingsUserEmails from "@pages/Private/Settings/email/Email/user-email";
import SettingsOrgEmails from "@pages/Private/Settings/email/OrgEmails";
import DataAdministrationExports from "@pages/Private/Settings/DataAdministration/exports";
import SettingsFormCreator from "@pages/Private/Settings/FormCreator";
import SettingsFormCreatorManage from "@pages/Private/Settings/FormCreator/manage/manage-form";
import SettingsPreviewForm from "@pages/Private/Settings/FormCreator/preview-form";
import AppSettings from "@pages/Private/Settings/Apps";

// import EmailTemplates from "@pages/Private/Settings/EmailTemplates/Services";
// import EmailNotification from "@pages/Private/Settings/EmailTemplates/EmailNotification";
// import EmailTemplatesSales from "@pages/Private/Settings/EmailTemplates/Sales";
// import EmailTemplatesTasks from "@pages/Private/Settings/EmailTemplates/Tasks";

export const SettingsRoutes = [
  // {
  //   path: "/:orgId/settings/",
  //   name: "Dashboard",
  //   component: Settings,
  // },
  {
    path: "/:orgId/settings/company-profile",
    name: "Dashboard",
    component: CompanyProfile,
  },
  {
    path: "/:orgId/settings/branding",
    name: "Dashboard",
    component: Branding,
  },
  // {
  //   path: "/:orgId/settings/app-branding",
  //   name: "Dashboard",
  //   component: AppBranding,
  // },

  //--------------------- Modules and Fields Detail routes --------------------//
  {
    path: "/:orgId/settings/module-and-field",
    name: "Dashboard",
    component: ModuleAndField,
  },
  {
    path: "/:orgId/settings/module-and-field/manage-fields/:id",
    name: "Dashboard",
    component: ManageFields,
  },

  {
    path: "/:orgId/settings/module-and-field/manage-fields/info/:id/:code",
    name: "Dashboard",
    component: SettingsModuleInfo,
  },
  {
    path: "/:orgId/settings/module-and-field/manage-fields/stages/:id/:code",
    name: "Dashboard",
    component: SettingsModuleStages,
  },
  {
    path: "/:orgId/settings/module-and-field/manage-fields/fields/:id/:code",
    name: "Dashboard",
    component: SettingsModuleFields,
  },

  //--------------------- Users --------------------//
  {
    path: "/:orgId/settings/users",
    name: "Dashboard",
    component: SettingUsers,
  },

  //--------------------- Email --------------------//
  {
    path: "/:orgId/settings/emails/config",
    name: "Dashboard",
    component: SettingsEmail,
  },
  {
    path: "/:orgId/user-emails/config",
    name: "Dashboard",
    component: SettingsUserEmails,
  },

  {
    path: "/:orgId/settings/emails/deliverability",
    name: "Dashboard",
    component: SettingsEmailDeliverability,
  },
  {
    path: "/:orgId/settings/emails/deliverability/add",
    name: "Dashboard",
    component: SettingsAddEmailDeliverability,
  },
  {
    path: "/:orgId/settings/emails/templates",
    name: "Dashboard",
    component: SettingsEmailTemplates,
  },
  {
    path: "/:orgId/settings/emails/org-emails",
    name: "Dashboard",
    component: SettingsOrgEmails,
  },

  //--------------------- Workflows --------------------//
  {
    path: "/:orgId/settings/workflows",
    name: "Dashboard",
    component: SettingsWorkflows,
  },
  {
    path: "/:orgId/settings/workflows/create",
    name: "Dashboard",
    component: SettingsWorkflowDetail,
  },
  {
    path: "/:orgId/settings/workflows/edit/:id",
    name: "Dashboard",
    component: SettingsWorkflowDetail,
  },
  {
    path: "/:orgId/settings/workflows/edit/:id",
    name: "Dashboard",
    component: SettingsWorkflowDetail,
  },

  //--------------------- Forms --------------------//
  {
    path: "/:orgId/settings/forms",
    name: "Dashboard",
    component: SettingsFormCreator,
  },
  {
    path: "/:orgId/settings/forms/create",
    name: "Dashboard",
    component: SettingsFormCreatorManage,
  },
  {
    path: "/:orgId/settings/forms/edit/:id",
    name: "Dashboard",
    component: SettingsFormCreatorManage,
  },

  // {
  //   path: "/:orgId/settings/web-forms/:slug",
  //   name: "Dashboard",
  //   component: SettingsPreviewForm,
  // },
  //-------------------------------- Email Templates -------------------------------- //
  {
    path: "/:orgId/settings/data-administration-exports",
    name: "Dashboard",
    component: DataAdministrationExports,
  },
  {
    path: "/:orgId/settings/apps",
    name: "Dashboard",
    component: AppSettings,
  },

  //-------------------------------- Email Templates -------------------------------- //

  // {
  //   path: "/:orgId/settings/email-templates/service",
  //   name: "Dashboard",
  //   component: EmailTemplates,
  // },
  // {
  //   path: "/:orgId/settings/email-templates/sales",
  //   name: "Dashboard",
  //   component: EmailTemplatesSales,
  // },
  // {
  //   path: "/:orgId/settings/email-templates/tasks",
  //   name: "Dashboard",
  //   component: EmailTemplatesTasks,
  // },
  // {
  //   path: "/:orgId/settings/email-templates/notification/add",
  //   name: "Dashboard",
  //   component: EmailNotification,
  // },
  // {
  //   path: "/:orgId/settings/email-templates/notification/:id",
  //   name: "Dashboard",
  //   component: EmailNotification,
  // },
];
