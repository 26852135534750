import React, { useEffect } from "react";
// import "./App.css";
import {
  BrowserRouter,
  Route,
  Routes,
  Navigate,
  useParams,
} from "react-router-dom";
import { PrivateRoutes } from "routes/private.routes";
import { PublicRoutes } from "routes/public.routes";
import NotFoundPage from "@pages/Public/404";
import ComfirmOrganization from "@pages/Private/Auth/confirm-org";
import { toast } from "sonner";
import SettingsPreviewForm from "@pages/Private/Settings/FormCreator/preview-form";
import SettingsPreviewFormThankyou from "@pages/Private/Settings/FormCreator/preview-form-thankyou";
import GenerateWhatsappLink from "@pages/Private/Whatsapp/generate-whatsapp-link";
import DuplicateImports from "@pages/Public/DuplicateImports";
import { getValue } from "@utils/lodash";

function App() {
  /* -------------------------------------------------------------------------- */
  /*                               Hooks Section                                */
  /* -------------------------------------------------------------------------- */
  /* -------------------------------------------------------------------------- */
  /*                               API Section                                  */
  /* -------------------------------------------------------------------------- */
  /* -------------------------------------------------------------------------- */
  /*                               Onchange section                             */
  /* -------------------------------------------------------------------------- */
  const params = useParams();
  // useEffect(() => {
  //   console.log(params);
  //   let path = window.location.pathname;
  //   let orgID = path.split("/")[1];
  //   const pusher = new Pusher("b999d44ea855263e20a6", {
  //     cluster: "ap2",
  //   });
  //   const channel = pusher.subscribe(`whats-app-${orgID}`);
  //   channel.bind("whats-app-message-notification", (data: any) => {
  //     // Method to be dispatched on trigger.
  //     console.log("NEW EVENT RECEIVED ::: ", {
  //       data: data,
  //     });
  //   });

  //   // Unsubscribe from the channel on component unmount
  //   return () => {
  //     channel.unbind_all();
  //     channel.unsubscribe();
  //   };
  // }, []);

  return (
    <div>
      <BrowserRouter>
        <Routes>
          {PublicRoutes.map((item, key) => {
            // eslint-disable-next-line
            const Element: any = item.component;
            return (
              <Route
                key={key}
                path={item.path}
                element={
                  !localStorage.getItem("accessToken") ? (
                    <Element />
                  ) : (
                    <Navigate to="/organisations" />
                  )
                }
              />
            );
          })}
          {PrivateRoutes.map((item: any, key) => {
            // eslint-disable-next-line
            const Element: any = item.component;
            return (
              <Route
                key={key}
                path={item.path}
                element={
                  localStorage.getItem("accessToken") ? (
                    <Element />
                  ) : (
                    <>
                      {window.location.pathname === "/organisations/join" ? (
                        <Navigate to={`/signin${window.location.search}`} />
                      ) : (
                        <Navigate to="/signin" />
                      )}
                    </>
                  )
                }
              />
            );
          })}

          <Route path={"/duplicate-imports"} element={<DuplicateImports />} />
          <Route
            path={"/generate-whatsapp-button"}
            element={<GenerateWhatsappLink />}
          />
          <Route
            path={"/organisations/join"}
            element={<ComfirmOrganization />}
          />
          <Route
            path={"/:orgId/settings/web-forms/:slug"}
            element={<SettingsPreviewForm />}
          />
          <Route
            path={"/:orgId/settings/web-forms/:slug/thankyou"}
            element={<SettingsPreviewFormThankyou />}
          />
          <Route path={"*"} element={<NotFoundPage />} />
        </Routes>
      </BrowserRouter>
    </div>
  );
}

export default App;
