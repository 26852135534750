import React, { useEffect, useRef, useState } from "react";
import Popup from "reactjs-popup";
import "reactjs-popup/dist/index.css";
import "./import-contact.scss";
import DropdownCloseSvgComponent from "@assets/svg/dropdown-close";
import { getValue } from "@utils/lodash";
import { mediaUpload } from "@services/upload.service";
import {
  createDataImport,
  createDataImportEntry,
} from "@services/imports-exports.service";
import { formatString, sortJSONObjectArray } from "@common/text-helpers";
import ImportStep1 from "./components/import-step-1";
import ImportStep2 from "./components/import-step-2";
import ImportStep3 from "./components/import-step-3";
import ImportStep4 from "./components/import-step-4";
import ImportStep5 from "./components/import-step-5";
import ImportSubmitButton from "./components/submit-button";
import { toast } from "sonner";
import SimpleReactValidator from "simple-react-validator";
import CancelAlertImportPopup from "./components/popup/cancel-alert-import";
import { handleDownloadOnClick } from "@common/downloadfile";
import { useNavigate, useParams } from "react-router-dom";
import { Tooltip } from "antd";

function CommonImportPopoup(props: any) {
  const params = useParams();
  const navigate = useNavigate();
  const [selectedOption, setSelectedOption] = useState("CSV");
  const [currentStep, setCurrentStep] = useState(1);
  const [showConfirmation, setShowConfirmation] = useState(false);
  const [showConfirmation1, setShowConfirmation1] = useState(false);
  const handleCancelGoBack = () => {
    setShowConfirmation1(false);
  };
  const handleToggleConfirm = () => {
    setShowConfirmation1(!showConfirmation1);
  };
  const simpleValidator = useRef(new SimpleReactValidator());
  const [, forceUpdate] = useState(0);

  /* -------------------------------------------------------------------------- */
  /*                                  Popup Section                             */
  /* -------------------------------------------------------------------------- */
  const popupRef = useRef<any>(null);
  const handleClose = () => {
    if (popupRef.current) {
      popupRef.current.close();
    }
  };
  const handleOpen = () => {
    if (popupRef.current) {
      popupRef.current.open();
    }
  };

  const reset = () => {
    setCurrentStep(1);
    setRequest({
      ...request,
      date_format: "DDMMYYYY",
      import_type: "add",
      match_field: "",
      pipeline_stage_id: "",
      owner_email: "",
      owner_id: "",
    });
    setInfo({});
  };

  /* -------------------------------------------------------------------------- */
  /*                               UseState Section                             */
  /* -------------------------------------------------------------------------- */

  const [request, setRequest] = useState({
    date_format: "DDMMYYYY",
    import_type: "add",
    match_field: "",
    pipeline_stage_id: "",
    owner_email: "",
    owner_id: "",
  });

  /* -------------------------------------------------------------------------- */
  /*                            Upload Section                                  */
  /* -------------------------------------------------------------------------- */

  const [uploadLoading, setUploadLoading] = useState(false);
  const [uploadedFileInfo, setUploadedFileInfo] = useState({});
  const [info, setInfo] = useState({});

  const handleUploadDocuments = async (e: any) => {
    Array.from(e).forEach(async (item: any) => {
      let formData = new FormData();
      formData.append("file", item);
      formData.append("filename", item.name);
      formData.append("is_public", "true");
      formData.append("collection", "public-images");
      try {
        setUploadLoading(true);
        let resp = await mediaUpload(formData);
        if (resp) {
          setUploadedFileInfo(getValue(resp, `data`, {}));
          let obj = {
            api_name: getValue(props, `module`, ""),
            module_id: getValue(props, `moduleId`, "")
              ? getValue(props, `moduleId`, "")
              : getValue(props, `selectedPipeline.module_id`, ""),
            file_id: getValue(resp, `data.id`, ""),
            pipeline_id: getValue(props, `selectedPipeline.id`, ""),
            file_type: getValue(resp, `data.extension`, "").split(".")[1],
          };
          let response = await createDataImport(obj);
          if (response) {
            setInfo(getValue(response, `data`, {}));
            setCurrentStep(3);
            let system_fields = getValue(
              response,
              `data.system_fields`,
              []
            ).map((item: any) => ({
              ...item,
              value: getValue(item, `name`, ""),
            }));
            let match_fields = getValue(response, `data.match_fields`, []).map(
              (item: any) => ({
                ...item,
                value: getValue(item, `name`, ""),
              })
            );
            let column_mapping = getValue(response, `data.column_mapping`, []);

            setInfo({
              ...getValue(response, `data`, {}),
              system_fields: system_fields,
              match_fields: match_fields,
              column_mapping: column_mapping,
            });

            setRequest({
              ...request,
              match_field: getValue(
                response,
                `data.match_fields[${0}].name`,
                ""
              ),
            });
          }

          e = null;
          setUploadLoading(false);
        } else {
          e = null;
          setUploadLoading(false);
        }
      } catch (error) {
        e = null;
        setUploadLoading(false);
      }
    });
  };

  /* -------------------------------------------------------------------------- */
  /*                               API Section                                  */
  /* -------------------------------------------------------------------------- */
  const [submitLoading, setSubmitLoading] = useState(false);
  const handleSubmitData = async () => {
    const formValid = simpleValidator.current.allValid();
    if (!formValid) {
      simpleValidator.current.showMessages();
      forceUpdate(1);
    } else {
      try {
        let infoObject = JSON.parse(JSON.stringify(info));
        let columnMapping = getValue(info, `column_mapping`, []).filter(
          (item: object) => getValue(item, `mapped_field_name`, "")
        );
        setSubmitLoading(true);
        let resp = await createDataImportEntry({
          ...request,
          ...infoObject,
          column_mapping: columnMapping,
          api_name: getValue(props, `module`, ""),
        });
        if (resp) {
          props.getListData(getValue(props, `selectedPipeline.id`, ""), "");
          handleClose();
          setSubmitLoading(false);
          // toast.success('abc')
          let message = `Status : ${formatString(
            getValue(resp, `data.status`, "")
          )} & Failed Count : ${getValue(resp, `data.failed_count`, 0)}`;
          if (getValue(resp, `data.failed_count`, 0) === 0) {
            toast.success("Imported Successfully");
          } else {
            toast.warning(message);
          }
          setCurrentStep(1);
          setInfo({});
          simpleValidator.current.hideMessages();
          forceUpdate(0);
          // if (getValue(resp, `data.failed_count`, 0)) {
          //   handleDownloadOnClick(getValue(resp, `data.log_file_url`, ""), "");
          // }
          navigate(
            `/${getValue(params, `orgId`, "")}/crm/${getValue(
              props,
              `module`,
              ""
            )}/imports`
          );
        } else {
          props.getListData(getValue(props, `selectedPipeline.id`, ""), "");
          handleClose();
          setSubmitLoading(false);
          setCurrentStep(1);
        }
      } catch (error) {
        setSubmitLoading(false);
      }
    }
  };

  /* -------------------------------------------------------------------------- */
  /*                               Onchange section                             */
  /* -------------------------------------------------------------------------- */

  const handleChange = (value: any) => {
    setSelectedOption(value);
  };

  return (
    <Popup
      trigger={
        <Tooltip title="Upload">
          <img
            src="/images/icons/upload_cloud.svg"
            className={"filter-image"}
          />
        </Tooltip>
      }
      modal
      closeOnDocumentClick={false}
      onClose={handleCancelGoBack}
      className="import-contact-wrapper"
      onOpen={handleOpen}
      ref={popupRef}
    >
      <div className="import-contact-container position-relative">
        <div className="gradient-strip"></div>
        <div>
          {getValue(request, "match_field", "") && (
            <button
              className="close import-contact-close"
              onClick={handleToggleConfirm}
            >
              <DropdownCloseSvgComponent />
            </button>
          )}
        </div>
        <div className="import-contact-body">
          {currentStep === 1 && (
            <ImportStep1
              handleChange={handleChange}
              selectedOption={selectedOption}
              module={props.module}
              getImportLog={props.getImportLog}
            />
          )}
          {/* {currentStep === 2 && (
            <ImportStep2
              handleChange={handleChange}
              selectedOption={selectedOption}
            />
          )} */}
          {currentStep === 2 && (
            <ImportStep3
              handleUploadDocuments={handleUploadDocuments}
              isLoading={uploadLoading}
              uploadedFileInfo={uploadedFileInfo}
            />
          )}
          {currentStep === 3 && (
            <ImportStep4
              info={info}
              setInfo={setInfo}
              uploadedFileInfo={uploadedFileInfo}
            />
          )}
          {currentStep === 4 && (
            <ImportStep5
              info={info}
              request={request}
              setRequest={setRequest}
              stages={props.stages}
              validator={simpleValidator}
              module={props.module}
            />
          )}
        </div>
        <div className="contact-bottom-buttons">
          <ImportSubmitButton
            currentStep={currentStep}
            setCurrentStep={setCurrentStep}
            handleClose={handleClose}
            setShowConfirmation={setShowConfirmation}
            showConfirmation={showConfirmation}
            handleSubmitData={handleSubmitData}
            submitLoading={submitLoading}
            info={info}
            setInfo={setInfo}
            disabled={getValue(request, `match_field`, "")}
          />
        </div>
      </div>

      <CancelAlertImportPopup
        open={showConfirmation1}
        onClose={handleCancelGoBack}
        request={request}
        handleClose={handleClose}
        onConfirm={() => {
          handleClose();
          reset();
        }}
      />
    </Popup>
  );
}

export default CommonImportPopoup;
