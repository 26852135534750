import Dashboard from "@pages/Private/Dashboard";
import ModuleImportLogs from "@pages/Private/ImportLogs";

export const DashboardRoutes = [
  // dashboard
  { path: "/:orgId/dashboard", name: "Dashboard", component: Dashboard },
  {
    path: "/:orgId/crm/:module/imports",
    name: "Dashboard",
    component: ModuleImportLogs,
  },
];
