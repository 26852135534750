import { AuthRoutes } from "./private/auth.routes";
import { CompaniesRoutes } from "./private/companies.routes";
import { ContactsRoutes } from "./private/contacts.routes";
import { DashboardRoutes } from "./private/dashboard.routes";
// import { DispatchRoutes } from "./private/dispatch.routes";
// import { MoreRoutes } from "./private/more.routes";
import { OrganizationRoutes } from "./private/orgnization.routes";
import { SalesRoutes } from "./private/sales.routes";
import { SettingsRoutes } from "./private/settings.routes";
// import { TaskRoutes } from "./private/tasks.route";
import { TasksRoutes } from "./private/activity.routes";
import { MarketingRoutes } from "./private/marketing.routes";
import { ChatbotRoutes } from "./private/chatbot.routes";
import { PropertyRoutes } from "./private/property.routes";
import { LeadsRoutes } from "./private/leads.routes";
// import { CommonRoutes } from "./public/common.routes";

export const PrivateRoutes = [
  ...DashboardRoutes,
  // ...DispatchRoutes,
  ...SettingsRoutes,
  // ...MoreRoutes,
  ...SalesRoutes,
  ...TasksRoutes,
  // ...CommonRoutes,
  ...OrganizationRoutes,
  ...ContactsRoutes,
  ...LeadsRoutes,
  ...CompaniesRoutes,
  // ...TaskRoutes,
  ...AuthRoutes,
  ...MarketingRoutes,
  ...ChatbotRoutes,
  ...PropertyRoutes,
];
