import { getStartOfMonthDate } from "@common/date-helpers";
import { sortJSONObjectArray } from "@common/text-helpers";
import { listAllModules } from "@services/modules.service";
import {
  getProfileInfo,
  getSpecificOrganisations,
  getSpecificProfile,
  listOfOrganisations,
} from "@services/organisations.service";
import { getValue } from "@utils/lodash";
import {
  createContext,
  useCallback,
  useContext,
  useEffect,
  useState,
} from "react";
import Pusher from "pusher-js";
import { config } from "../env";
import { QueryRequestHelper } from "@common/query-request-helper";
import { getWhatsappConversations } from "@services/fb.service";

const StateContext = createContext();

export function StateProvider({ children }) {
  const [organizationList, setOrganizationList] = useState([]);
  const [userInfo, setUserInfo] = useState({});
  const [orgInfo, setOrgInfo] = useState({});
  const [modules, setModules] = useState([]);
  const [allModules, setAllModules] = useState([]);
  const [currentCRMUrl,setCurrentCRMUrl] = useState(``)
  /* -------------------------------------------------------------------------- */
  /*                                UseEffect Section                           */
  /* -------------------------------------------------------------------------- */

  const [newNotification, setNewNotification] = useState(false);
  const [newStatus, setNewStatus] = useState({});

  useEffect(() => {
    getAllCount();
    let url_leangth = window.location.pathname.split("/");
    if (getValue(localStorage, `accessToken`, "")) {
      getOrganizationList();
      if (!getValue(userInfo, `email`, "")) {
        getOrganizationUser();
      }
      if (getValue(url_leangth, `length`, 0) > 2) {
        getOrganizationInfo();
        getModules();
        getProfile();
      }
    }

    //chat context
    let path = window.location.pathname;
    let orgID = path.split("/")[1];
    const pusher = new Pusher(config.PUSHER_APP_KEY, {
      cluster: config.PUSHER_APP_CLUSTER,
    });
    const channel = pusher.subscribe(`whats-app-${orgID}`);
    channel.bind("whats-app-message-notification", (data) => {
      // Method to be dispatched on trigger.
      // console.log("NEW MESSAGE EVENT RECEIVED ::: ", {
      //   data: data,
      // });
      setNewStatus(data);
      setNewNotification(true);
      playNotificationSound();
      getAllCount();
    });
    channel.bind("whats-app-message-status-notification", (data) => {
      // Method to be dispatched on trigger.
      // console.log("NEW STATUS EVENT RECEIVED ::: ", {
      //   data: data,
      // });
      setNewStatus(data);
      // setNewNotification(true);
      // playNotificationSound();
    });

    // Unsubscribe from the channel on component unmount
    return () => {
      channel.unbind_all();
      channel.unsubscribe();
    };
  }, []);

  function playNotificationSound() {
    const sound = new Audio("/notification.mp3");
    sound.play().catch((error) => {
      console.error("Failed to play the sound:", error);
    });
  }

  /* -------------------------------------------------------------------------- */
  /*                                     API Section                            */
  /* -------------------------------------------------------------------------- */
  const getModules = async () => {
    try {
      let resp = await listAllModules("");
      if (resp) {
        setModules(
          concatenateAndRemoveDuplicates(
            sortJSONObjectArray(getValue(resp, `data`, {}), "seq_num")
          )
        );
        setAllModules(
          allModulesFun(
            sortJSONObjectArray(getValue(resp, `data`, {}), "seq_num")
          )
        );
      }
    } catch (error) {}
  };
  const allModulesFun = (modules) => {
    let orgID = window.location.pathname.split("/")[1];

    let moduleList = modules.map((item) => ({
      linkText: getValue(item, `label`, ""),
      linkUrl: `/${orgID}/activity/${getValue(item, `api_name`, "")}`,
      activeRoute: getValue(item, `api_name`, ""),
      id: getValue(item, `id`, ""),
      api_name: getValue(item, `api_name`, ""),
      name: getValue(item, `api_name`, ""),
      orgID: orgID,
      type: getValue(item, `type`, ""),
      label_singular: getValue(item, `label_singular`, ""),
    }));

    // Remove duplicates based on linkUrl
    let uniqueArray = moduleList.filter((item, index) => {
      return (
        moduleList.findIndex((el) => el.linkUrl === item.linkUrl) === index
      );
    });

    return uniqueArray;
  };
  const concatenateAndRemoveDuplicates = (modules) => {
    let date_range = getStartOfMonthDate();
    let orgID = window.location.pathname.split("/")[1];
    // let array = [
    //   {
    //     linkText: "Dashboard",
    //     linkUrl: `/${orgID}/dashboard?date_range=${date_range}`,
    //     activeRoute: "dashboard",
    //     api_name: "dashboard",
    //     name: "dashboard",
    //   },
    // ];
    // let ActivityModules =
    //   getValue(modules, `length`, 0) > 0
    //     ? modules.filter((item) => getValue(item, `type`, "") === "ACTIVITY")
    //     : [];
    let PipelineModules =
      getValue(modules, `length`, 0) > 0
        ? modules.filter((item) => getValue(item, `type`, ""))
        : [];
    // let ActivityModulesList = ActivityModules.map((item) => ({
    //   linkText: getValue(item, `label`, ""),
    //   linkUrl: `/${orgID}/activity/${getValue(item, `api_name`, "")}`,
    //   activeRoute: getValue(item, `api_name`, ""),
    //   id: getValue(item, `id`, ""),
    //   api_name: getValue(item, `api_name`, ""),
    //   name: getValue(item, `api_name`, ""),
    //   orgID: orgID,
    //   type: getValue(item, `type`, ""),
    //   label_singular: getValue(item, `label_singular`, ""),
    // }));
    let PipelineModulesList = PipelineModules.map((item) => ({
      linkText: getValue(item, `label`, ""),
      linkUrl: `/${orgID}/crm/${getValue(item, `api_name`, "")}`,
      activeRoute: getValue(item, `api_name`, ""),
      id: getValue(item, `id`, ""),
      api_name: getValue(item, `api_name`, ""),
      name: getValue(item, `api_name`, ""),
      orgID: orgID,
      type: getValue(item, `type`, ""),
      label_singular: getValue(item, `label_singular`, ""),
    }));
    setCurrentCRMUrl(`/${orgID}/crm/${getValue(PipelineModules, `[${0}].api_name`, "")}`)

    // let inbox = [
    //   {
    //     linkText: "Broadcast",
    //     linkUrl: `/${orgID}/broadcast`,
    //     activeRoute: "broadcast",
    //     api_name: "broadcast",
    //     name: "broadcast",
    //   },
    //   {
    //     linkText: "Inbox",
    //     linkUrl: `/${orgID}/inbox`,
    //     activeRoute: "inbox",
    //     api_name: "inbox",
    //     name: "inbox",
    //   },
    // ];

    // let activityList = [
    //   {
    //     linkText: "Activities",
    //     linkUrl: `/${orgID}/activity/tasks`,
    //     activeRoute: "activity",
    //     api_name: "activity",
    //     name: "activity",
    //     subMenu: ActivityModulesList,
    //   },
    // ];

    // Concatenate the arrays
    // let combinedArray = array.concat(PipelineModulesList, inbox);
    let combinedArray = PipelineModulesList;
    // Remove duplicates based on linkUrl
    let uniqueArray = combinedArray.filter((item, index) => {
      return (
        combinedArray.findIndex((el) => el.linkUrl === item.linkUrl) === index
      );
    });

    return uniqueArray;
  };
  const getOrganizationList = async () => {
    let resp = await listOfOrganisations("");
    if (resp) {
      setOrganizationList(getValue(resp, `data`, []));
    }
  };
  const getOrganizationUser = async () => {
    let resp = await getSpecificProfile();
    if (resp) {
      setUserInfo(getValue(resp, `data`, {}));
    }
  };
  const getOrganizationInfo = async () => {
    try {
      let resp = await getSpecificOrganisations();
      if (resp) {
        setOrgInfo(getValue(resp, `data`, {}));
        document.documentElement.style.setProperty(
          "--primary-color",
          getValue(resp, "data.theme", "")
            ? hexToRgbA(getValue(resp, "data.theme", "#D7242E"))
            : hexToRgbA("#D7242E")
        );
        document.documentElement.style.setProperty(
          "--button-primary",
          getValue(resp, "data.theme", "")
            ? hexToRgbA(getValue(resp, "data.theme", "#D7242E"))
            : hexToRgbA("#D7242E")
        );
        document.documentElement.style.setProperty(
          "--secondary-color",
          getValue(resp, "data.secondary_color", "")
            ? hexToRgbA(getValue(resp, "data.secondary_color", "#0d0d73"))
            : hexToRgbA("#0d0d73")
        );
        document.documentElement.style.setProperty(
          "--color",
          getValue(resp, "data.text_color", "#ffffff")
            ? hexToRgbA(getValue(resp, "data.text_color", "#ffffff"))
            : hexToRgbA("#ffffff")
        );
      } else {
        setOrgInfo({});
      }
    } catch (error) {
      setOrgInfo({});
    }
  };
  function hexToRgbA(hex) {
    var c;
    if (/^#([A-Fa-f0-9]{3}){1,2}$/.test(hex)) {
      c = hex.substring(1).split("");
      if (c.length == 3) {
        c = [c[0], c[0], c[1], c[1], c[2], c[2]];
      }
      c = "0x" + c.join("");
      return [(c >> 16) & 255, (c >> 8) & 255, c & 255].join(",");
      // return 'rgba('+[(c>>16)&255, (c>>8)&255, c&255].join(',')+',1)';
    }
    throw new Error("Bad Hex");
  }

  const [userId, setUserId] = useState("");
  const [profileInfo, setProfileInfo] = useState({});
  const getProfile = async () => {
    try {
      let resp = await getProfileInfo();
      if (resp) {
        setUserId(getValue(resp, `data.id`, ""));
        setProfileInfo(getValue(resp, `data`, {}));
      }
    } catch (error) {}
  };
  /* -------------------------------------------------------------------------- */
  /*                                  Callback Section                          */
  /* -------------------------------------------------------------------------- */
  const handleChangeModule = useCallback(() => {
    getModules();
  }, []);
  const handleChangeOrgainization = useCallback(() => {
    getOrganizationInfo();
  }, []);
  const getUserIDCallback = useCallback(() => {
    getProfile();
  }, []);
  const getUserInfoCallback = useCallback(() => {
    getOrganizationUser();
  }, []);

  const selectedModuleId = async (module_name) => {
    let resp = await listAllModules("");
    if (module_name) {
      let moduleInfo = getValue(resp, `data`, []).find(
        (item) => getValue(item, `api_name`, "") === module_name
      );
      return getValue(moduleInfo, `id`, "");
    } else {
      let name = window.location.pathname.split("/")[2];
      let moduleInfo = getValue(resp, `data`, []).find(
        (item) => getValue(item, `api_name`, "") === name
      );
      return getValue(moduleInfo, `id`, "");
    }
  };

  /* -------------------------------------------------------------------------- */
  /*                                  Whatsapp Section                          */
  /* -------------------------------------------------------------------------- */

  const urlSearchParams = new URLSearchParams(window.location.search);
  const UrlParams = Object.fromEntries(urlSearchParams.entries());

  const getAllCount = () => {
    getUnAssignedCount();
    getAssignedCount();
    getMyCount();
    getResolvedCount();
  };
  const [unassignedCount, setUnAssignedCount] = useState(0);
  const getUnAssignedCount = async () => {
    let count = await getUnreadCount(1, "", "un-assigned", "");
    setUnAssignedCount(count);
  };
  const [assignedCount, setAssignedCount] = useState(0);
  const getAssignedCount = async () => {
    let count = await getUnreadCount(1, "", "assigned", userId);
    setAssignedCount(count);
  };
  const [myCount, setMyCount] = useState(0);
  const getMyCount = async () => {
    let count = await getUnreadCount(1, "", "assigned", "");
    setMyCount(count);
  };
  const [resolvedCount, setResolvedCount] = useState(0);
  const getResolvedCount = async () => {
    let count = await getUnreadCount(1, "", "resolved", "");
    setResolvedCount(count);
  };

  const getUnreadCount = async (page_no, searchText, convStatus, userId) => {
    let waAccountIds = urlSearchParams.getAll("waAccountIds[]");
    try {
      let payload = {
        page_no: page_no,
        page_size: getValue(UrlParams, `page_size`, ""),
        search: getValue(UrlParams, `searchText`, "")
          ? getValue(UrlParams, `searchText`, "")
          : searchText,
        conversationStatus: convStatus,
        forOrgUser: userId,
        waAccountIds: waAccountIds,
        onlyUnread: getValue(UrlParams, `onlyUnread`, ""),
      };
      let queryRequest = QueryRequestHelper(payload);
      let resp = await getWhatsappConversations(queryRequest);
      if (resp) {
        return getValue(resp, `data.unread`, 0);
      } else {
      }
    } catch (error) {}
  };
  const totalInboxCount = () => {
    return unassignedCount + assignedCount + myCount + resolvedCount;
  };
  return (
    <StateContext.Provider
      value={{
        organizationList,
        setOrganizationList,
        userInfo,
        setUserInfo,
        orgInfo,
        modules,
        userId,
        allModules,
        profileInfo,
        //calbacks
        handleChangeModule,
        handleChangeOrgainization,
        getUserIDCallback,
        selectedModuleId,
        setNewNotification,
        getUserInfoCallback,
        newNotification,
        newStatus,
        setNewStatus,
        unassignedCount,
        assignedCount,
        myCount,
        resolvedCount,
        getAllCount,
        totalInboxCount,
        currentCRMUrl
      }}
    >
      {children}
    </StateContext.Provider>
  );
}

export function useStateContext() {
  return useContext(StateContext);
}
